import { Container, LoadingOverlay, Stack } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { API_URL } from "../../services/url";

const JobScheduledDetails = () => {

  const isMobile = useMediaQuery("(max-width: 600px)");

  const [loading, setLoading] = useState(false);

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}/job-scheduled-details`);
        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }
  , []);

  return (
    <Container
      size={isMobile ? "xs" : "sm"}
      px="lg"
      py={isMobile ? "md" : "xl"}
      style={{
        // height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "self-start",
        textAlign: "left",
      }}
    >
      <LoadingOverlay visible={loading} />

      <Stack style={{ width: "100%" }}></Stack>
    </Container>
  );
};

export default JobScheduledDetails;
