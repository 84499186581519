import {
  Box,
  Button,
  Container,
  Flex,
  LoadingOverlay,
  Menu,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
  rem,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { IconArrowLeft } from "@tabler/icons-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../../services/url";
import { formatProfileDateFormat } from "../../utils/datetime";

type UserProps = {
  id: string;
  email: string;
  phonenumber: string;
  first_name: string;
  last_name: string;
  dob: string;
  gender: string;
};

export const Profile: React.FC = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [original_data, setOriginalData] = useState<UserProps | null>(null);
  const [data, setData] = useState<UserProps | null>(null);

  const [invalidphone, setInvalidPhone] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [editmode, setEditmode] = useState(false);
  const [opened, setOpened] = useState(false);

  const driver = localStorage.getItem("userId");

  const fetchProfile = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}driver/${driver}`);
      console.log("🚀 ~ fetch ~ response:", response.data);
      if (response.data) {
        setOriginalData(response.data);
        setData(response.data);
      }
    } catch (error) {
      showNotification({
        title: "error",
        message: "Faild to Fetch Profile",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  // To Fetch Details
  useEffect(() => {
    fetchProfile();
  }, []);

  function goBack(): void {
    window.location.href = "/home";
  }

  const updateState = (key: string, value: any) => {
    if (data) setData({ ...data, [key]: value });
  };

  const handleEdit = () => {
    if (editmode) {
      setData(original_data);
    }
    setEditmode(!editmode);
  };

  const hasDataChanged = () => {
    if (!data || !original_data) return false;
    return JSON.stringify(data) !== JSON.stringify(original_data);
  };

  const isInvalidPhonenumber = () => {
    if (!data) return;
    const re = /^(?:\+?61|0)[2-478]\d{8}$/;
    if (!re.test(data?.phonenumber)) {
      setInvalidPhone(true);
      setError("Invalid phone number");
    } else {
      setInvalidPhone(false);
      setError("");
    }
  };

  const userKeys: (keyof UserProps)[] = ['phonenumber', 'first_name', 'last_name', 'dob', 'gender'];

  const handleUpdate = async () => {
    if (!data) return;
    setLoading(true);
    try {
      const formData = new FormData();
      
      userKeys.forEach(key => {
        if (key in data) {
          if (key === 'dob') {
            formData.append(key, formatProfileDateFormat(data[key]));
          } else {
            formData.append(key, String(data[key]));
          }
        }
      });
  
      const response = await axios.put<UserProps>(
        `${API_URL}driver/${driver}/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      console.log(
        "🚀 ~ handleUpdate ~ response:",
        Object.fromEntries(formData),
        response.data
      );
  
      if (response.data) {
        setOriginalData(data);
        setData(data);
        setEditmode(false);
        showNotification({
          title: "Success",
          message: "Profile Updated Successfully",
          color: "green",
        });
      }
    } catch (error) {
      console.error("Update error:", error);
      showNotification({
        title: "Error",
        message: "Failed to Update Profile",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  const handlelogout = () => {
    window.localStorage.clear();
    window.location.href = "/";
    showNotification({
      title: "Success",
      message: "Logout successfully",
      color: "green",
    });
  };

  const logoutmodal = () =>
    modals.openConfirmModal({
      title: "Logout",
      centered: true,
      children: <Text size="sm">Are you sure want to Logout?</Text>,
      labels: {
        confirm: "Logout",
        cancel: "Cancel",
      },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        handlelogout();
      },
      confirmProps: { color: "red" },
    });

  if (!data) return null;

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <Container
      size={isMobile ? "xs" : "sm"}
      px="lg"
      py={isMobile ? "md" : "xl"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "self-start",
        textAlign: "left",
      }}
      bg={"bg"}
    >
      <Flex align={"center"} mb={"lg"} gap={"sm"} w={"100%"}>
        <IconArrowLeft
          style={{ width: rem(20), height: rem(20) }}
          stroke={1.5}
          onClick={goBack}
        />
        <Title order={2} fw={700}>
          Profile
        </Title>
      </Flex>

      <Stack
        style={{
          width: "100%",
          border: "1px solid #091f5b",
          borderRadius: "10px",
        }}
        p="lg"
        gap={"lg"}
        mb={"lg"}
      >
        <Box>
          <Text c={"hcolor"} size={"sm"}>{"First Name"}</Text>
          {editmode ? (
            <TextInput
              placeholder="Ex: John Doe"
              required
              size="md"
              name="first_name"
              labelProps={{ style: { alignSelf: "flex-start" } }}
              value={data?.first_name}
              onChange={(event) =>
                updateState(event.currentTarget.name, event.currentTarget.value)
              }
            />
          ) : (
            <Text
              size={"md"}
              fw={500}
              style={{ color: data.first_name ? "black" : "gray" }}
            >
              {data?.first_name || "none"}
            </Text>
          )}
        </Box>

        <Box>
          <Text c={"hcolor"} size={"sm"}>{"Last Name"}</Text>
          {editmode ? (
            <TextInput
              placeholder="Ex: John Doe"
              size="md"
              name="last_name"
              labelProps={{ style: { alignSelf: "flex-start" } }}
              value={data?.last_name}
              onChange={(event) =>
                updateState(event.currentTarget.name, event.currentTarget.value)
              }
            />
          ) : (
            <Text
              size={"md"}
              fw={500}
              style={{ color: data.last_name ? "black" : "gray" }}
            >
              {data?.last_name || "none"}
            </Text>
          )}
        </Box>

        <Box>
          <Text c={"hcolor"} size={"sm"}>{"Phone Number"}</Text>
          {editmode ? (
            <TextInput
              placeholder="Enter your phone number"
              type="tel"
              size="md"
              name="phonenumber"
              error={invalidphone}
              labelProps={{ style: { alignSelf: "flex-start" } }}
              value={data.phonenumber}
              onChange={(event) =>
                updateState(event.currentTarget.name, event.currentTarget.value)
              }
              onBlur={isInvalidPhonenumber}
            />
          ) : (
            <Text
              size={"md"}
              fw={500}
              style={{ color: data.phonenumber ? "black" : "gray" }}
            >
              {data?.phonenumber || "none"}
            </Text>
          )}
        </Box>

        <Box>
          <Text c={"hcolor"} size={"sm"}>{"Date Of Birth"}</Text>
          {editmode ? (
            <Menu
              opened={opened}
              onChange={setOpened}
              trigger="click"
              position="bottom"
              withArrow
            >
              <Menu.Target>
                <TextInput
                  placeholder="Select your date of birth"
                  mb="sm"
                  size="md"
                  labelProps={{ style: { alignSelf: "flex-start" } }}
                  onClick={() => setOpened(true)}
                  value={
                    data.dob ? new Date(data.dob).toLocaleDateString() : ""
                  }
                  readOnly
                />
              </Menu.Target>
              <Menu.Dropdown>
                <DatePicker
                  allowDeselect
                  maxDate={new Date()}
                  value={data.dob ? new Date(data.dob) : new Date()}
                  onChange={(val) => {
                    updateState("dob", val);
                    setOpened(false);
                  }}
                />
              </Menu.Dropdown>
            </Menu>
          ) : (
            <Text
              size={"md"}
              fw={500}
              style={{ color: data.dob ? "black" : "gray" }}
            >
              {data.dob !== "" && data.dob
                ? new Date(data.dob).toLocaleDateString()
                : "none"}
            </Text>
          )}
        </Box>

        <Box>
          <Text c={"hcolor"} size={"sm"}>{"Gender"}</Text>
          {editmode ? (
            <Select
              placeholder="Select your gender"
              size="md"
              labelProps={{ style: { alignSelf: "flex-start" } }}
              data={[
                { value: "Male", label: "Male" },
                { value: "Female", label: "Female" },
                { value: "Other", label: "Other" },
              ]}
              value={data.gender}
              onChange={(value) => updateState("gender", value)}
            />
          ) : (
            <Text
              size={"md"}
              fw={500}
              style={{ color: data.gender ? "black" : "gray" }}
            >
              {data?.gender || "none"}
            </Text>
          )}
        </Box>

        <Box>
          <Text c={"hcolor"} size={"sm"}>{"Mail ID"}</Text>
          {editmode ? (
            <TextInput
              placeholder="Mail ID"
              size="md"
              labelProps={{ style: { alignSelf: "flex-start" } }}
              value={data?.email}
              readOnly
            />
          ) : (
            <Text
              size={"md"}
              fw={500}
              style={{ color: data.email ? "black" : "gray" }}
            >
              {data?.email || "none"}
            </Text>
          )}
        </Box>
      </Stack>
      <Flex
        justify={"flex-end"}
        align={"center"}
        columnGap={"lg"}
        w={"100%"}
        gap={"md"}
      >
        {editmode ? (
          <Flex w={"100%"} direction={"column"} align={"center"}>
            {hasDataChanged() && (
              <Button
                fullWidth
                size="lg"
                variant={"gradient"}
                gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
                mt={"sm"}
                disabled={loading || invalidphone}
                onClick={handleUpdate}
                loading={loading}
                loaderProps={{ type: "oval" }}
                radius={"md"}
              >
                {`Update`}
              </Button>
            )}
            <Button
              fullWidth
              size="lg"
              color="primary"
              variant="outline"
              mt={"sm"}
              onClick={handleEdit}
              loading={loading}
              loaderProps={{ type: "oval" }}
              radius={"md"}
            >
              Cancel
            </Button>
          </Flex>
        ) : (
          <Flex w={"100%"} direction={"column"} align={"center"} gap={"md"}>
            <Button
              fullWidth
              size="lg"
              variant="gradient"
              c={"fcolor"}
              gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
              mt={"sm"}
              onClick={handleEdit}
              radius={"md"}
            >
              Edit
            </Button>

            <Button
              fullWidth
              size="lg"
              color="red"
              variant="filled"
              onClick={logoutmodal}
              mt={"sm"}
              radius={"md"}
            >
              Logout
            </Button>
          </Flex>
        )}
      </Flex>
    </Container>
  );
};
