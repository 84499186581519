import {
  ActionIcon,
  Box,
  Chip,
  Container,
  Flex,
  Group,
  LoadingOverlay,
  Select,
  Tabs,
  Text,
  TextInput,
  Title,
  rem,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import {
  IconBell,
  IconPlus,
  IconSearch,
  IconUserCircle,
  IconX,
  IconClock,
  IconCopy,
  IconUserFilled,
  IconAlertOctagon,
  IconCircleCheck,
  IconXboxX,
  IconCalendarMonth,
  IconFolder
} from "@tabler/icons-react";
import axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import { API_URL } from "../../services/url";
import checkUserId from "../../utils/checkuserid";
import { CarSeaterList, SortByList } from "../CreateJob/constants";
import { JobInfoProps } from "../CreateJob/type";
import AllJobs from "./alljobs";
import MyJobs from "./myjobs";
import classes from "./tab.module.css";

const Home = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [jobList, setJobList] = useState<JobInfoProps[]>([]);

  const [myJobList, setMyJobList] = useState<JobInfoProps[]>([]);

  const [chips, setChips] = useState('all');

  const [invitedJobList, setInvitedJobList] = useState<JobInfoProps[]>([]);

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");

  const [sortby, setSortby] = useState<string | null>("pickup_at#asc");

  const [requestedlist, setRequestedlist] = useState<string[]>();

  // const [type, setType] = useState<string | null>("pickup_at");

  const [serviceBy, setServiceby] = useState<string | null>("");

  const [invite_id, setInvite_id] = useState([]) 

  const driverId = localStorage.getItem("userId");

  useLayoutEffect(() => {
    checkUserId();
  }, []);

  const fetchJob = async () => {    
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}job-listings/`);
      console.log("🚀 ~ fetchJob ~ response:", response);
      setJobList(response.data);
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to fetch job details",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchJobinvite = async () => {    
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}job-invite/`);
      const id = response.data.map((Jobs: { job: any; }) => Jobs.job)
      setInvite_id(id)
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to fetch job invite details",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchMyJob = async () => {
    try {
      if (driverId === null) return;
      setLoading(true);
      const response = await axios.get(
        `${API_URL}driver/${driverId}/posted-jobs/`
      );
      if (response.data)
        if (response.data.jobs) setMyJobList(response.data.jobs);
      console.log("🚀 ~ fetchMyJob ~ response:", response);
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to fetch my job details",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchInvitedJob = async () => {
    try {
      if (driverId === null) return;
      setLoading(true);
      const response = await axios.get(
        `${API_URL}driver/${driverId}/invite-jobs/`
      );
      if (response.data)
        if (response.data.job_invites)
          setInvitedJobList(response.data.job_invites);
      console.log("🚀 ~ fetchInvitedJob ~ response:", response);
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to fetch invited job details",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJob();
    fetchMyJob();
    fetchJobinvite();
    fetchInvitedJob();
  }, []);

  const filterJob = async () => {
    try {
      setLoading(true);
      let content = sortby?.split("#") || ["", ""];
      console.log("🚀 ~ filterJob ~ content:", content);
      const response = await axios.get(
        `${API_URL}job-listings/?services_required=${serviceBy}&search=${search}&sort_by=${content[0]}&type=${content[1]}`
      );
      console.log("🚀 ~ fposetchJob ~ response:", response.data);
      setJobList(response.data);
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to fetch job details",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    filterJob();
  }, [search, sortby, serviceBy]);

  // Utility function to filter jobs based on the presence of status in chips
  const filterJobsByStatus = (jobs: JobInfoProps[], chips: string[]) => {
    if (chips.includes("upcoming_jobs")) {
      const currentTime = new Date();
      // Filter and sort upcoming jobs by pickup time
      return jobs
        .filter((job) => {
          const pickupTime = new Date(job.pickup_at);
          return job.status !== "cancelled" && job.status === 'pending' && pickupTime > currentTime;
        })
        .sort((a, b) => new Date(a.pickup_at).getTime() - new Date(b.pickup_at).getTime());
    }
    if (chips.includes("all")) {
      return jobs;
    }
    // For other cases, filter based on the chips array
    return jobs.filter((job) => chips.length === 0 || chips.includes(job.status));
  };
   
    
  // Use the utility function to filter both myJobList and invitedJobList
  const filteredMyJob = [
    ...filterJobsByStatus(myJobList, chips? [chips]:[]),
    ...filterJobsByStatus(invitedJobList, chips? [chips]:[]),
  ];

  const handleChipClick = (event: React.MouseEvent<HTMLInputElement>) => {
    if (event.currentTarget.value === chips) {
      setChips('all');
    }
  };

  // request list

  const fetchlist = async () => {
    try {
      const listres = await axios.get(
        `${API_URL}job-request/?driver=${driverId}`
      );
      const jobIds = listres.data.map((item: any) => item.job);
      setRequestedlist(jobIds);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchlist();
  }, []);

  return (
    <Container
      size={isMobile ? "xs" : "sm"}
      px="lg"
      py={isMobile ? "md" : "xl"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "self-start",
        textAlign: "left",
      }}
      bg={"bg"}
    >
      <LoadingOverlay visible={loading} />

      <Flex
        justify={"space-between"}
        align={"center"}
        w={"100%"}
        pos={"relative"}
      >
        <ActionIcon
          variant="filled"
          aria-label="Settings"
          size={"lg"}
          radius={"xl"}
          color="primary"
          onClick={() => (window.location.href = "/profile")}
        >
          <IconUserCircle
            style={{ width: "70%", height: "70%" }}
            stroke={1.5}
          />
        </ActionIcon>

        <Title
          order={2}
          ta={"center"}
          fw={700}
          pos={"absolute"}
          top={"50%"}
          left={"50%"}
          style={{ transform: "translate(-50%, -50%)" }}
        >
          {`Kabbie`}
        </Title>

        <Flex columnGap={"xs"}>
          <ActionIcon
            variant="filled"
            aria-label="Settings"
            size={"lg"}
            radius={"xl"}
            color="primary"
            onClick={() => (window.location.href = "/createjob")}
          >
            <IconPlus style={{ width: "70%", height: "70%" }} stroke={1.5} />
          </ActionIcon>

          <ActionIcon
            variant="filled"
            aria-label="Settings"
            size={"lg"}
            radius={"xl"}
            color="primary"
            onClick={() => (window.location.href = "/notifications")}
          >
            <IconBell style={{ width: "70%", height: "70%" }} stroke={1.5} />
          </ActionIcon>
        </Flex>
      </Flex>

      <Box w={"100%"} mt={"md"}>
        <Tabs defaultValue="alljobs" variant="unstyled" classNames={classes}>
          <Tabs.List>
            <Tabs.Tab size={"md"} w={"50%"} value="alljobs">
              All Jobs
            </Tabs.Tab>
            <Tabs.Tab size={"md"} w={"50%"} value="myjobs">
              My Jobs
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="alljobs" pt="md">
            <TextInput
              value={search}
              placeholder="Search Location, Booking ID, Suburb"
              onChange={(event) => setSearch(event.currentTarget.value)}
              w={"100%"}
              rightSectionPointerEvents="visible"
              rightSection={
                search === "" ? (
                  <IconSearch style={{ width: rem(20), height: rem(20) }} />
                ) : (
                  <IconX
                    style={{ width: rem(20), height: rem(20) }}
                    onClick={() => setSearch("")}
                  />
                )
              }
              labelProps={{ style: { alignSelf: "flex-start" } }}
            />

            <Flex justify={"space-between"} align={"center"} w={"100%"} mt="md">
              <Select
                w={"48%"}
                placeholder="Service Required"
                data={CarSeaterList}
                value={serviceBy}
                clearable
                onClear={() => setServiceby("")}
                onChange={(value) => setServiceby(value)}
              />
              <Select
                w={"48%"}
                placeholder="Sort By"
                data={SortByList}
                value={sortby}
                clearable
                onClear={() => setSortby("")}
                onChange={(value) => setSortby(value)}
              />
            </Flex>

            {jobList.length === 0 ? (
              <Text size="md" mt="lg" ta="center">
                No Jobs available
              </Text>
            ) : (
              (search !== "" || (sortby !== null && sortby !== "")) && (
                <Text size="xs" mt={"xs"}>
                  {`Showing ${jobList.length} Results`}{" "}
                </Text>
              )
            )}

            {jobList.map((job) => (
              <AllJobs
                job={job}
                driverId={driverId}
                key={job.id}
                requestlist={requestedlist}
              />
            ))}
          </Tabs.Panel>

          <Tabs.Panel value="myjobs">
            <Chip.Group multiple={false} value={chips} onChange={setChips} >
              <Group
                justify="flex-start"
                my="lg"
                wrap="nowrap"
                style={{ overflowX: "auto" }}
                className={isMobile ? 'hide-scrollbar': 'show-scrollbar'}
              >
                <Chip pb={'5px'} size="md" onClick={handleChipClick} icon ={<IconFolder style={{ width: rem(20), height: rem(20), marginRight:"8px" }} />} value="all">All</Chip>
                <Chip pb={'5px'} size="md" onClick={handleChipClick} icon ={<IconCalendarMonth style={{ width: rem(20), height: rem(20), marginRight:"8px" }} />} value="upcoming_jobs">Upcoming Jobs</Chip>                
                <Chip pb={'5px'} size="md" onClick={handleChipClick} icon ={<IconClock style={{ width: rem(20), height: rem(20), marginRight:"8px" }} />} value="pending">Pending</Chip>
                <Chip pb={'5px'} size="md" onClick={handleChipClick} icon ={<IconUserFilled style={{ width: rem(20), height: rem(20), marginRight:"8px" }} />} value="assigned">Assigned</Chip>
                <Chip pb={'5px'} size="md" onClick={handleChipClick} icon ={<IconCircleCheck  style={{ width: rem(20), height: rem(20), marginRight:"8px" }} />} value="completed">Completed</Chip>
                <Chip pb={'5px'} size="md" onClick={handleChipClick} icon ={<IconAlertOctagon style={{ width: rem(20), height: rem(20), marginRight:"8px" }} />} value="uncovered">Uncovered</Chip>
                <Chip pb={'5px'} size="md" onClick={handleChipClick} icon ={<IconXboxX style={{ width: rem(20), height: rem(20), marginRight:"8px" }} />} value="cancelled">Cancelled</Chip>
                <Chip pb={'5px'} size="md" onClick={handleChipClick} icon ={<IconCopy style={{ width: rem(20), height: rem(20), marginRight:"8px" }} />} value="duplicate">Duplicate</Chip>
              </Group>
            </Chip.Group>

            {filteredMyJob.length === 0 ? (
              <Text size="md" mt="lg" ta="center">
                No Jobs available
              </Text>
            ) : (
              (search !== "" || (sortby !== null && sortby !== "")) && (
                <Text size="xs">{`Showing ${filteredMyJob.length} Results`}</Text>
              )
            )}

            {filteredMyJob.map((job) => (
              <MyJobs job={job} driverId={driverId} key={job.id} id={invite_id} />
            ))}
          </Tabs.Panel>
        </Tabs>
      </Box>
    </Container>
  );
};

export default Home;
