import {
  ActionIcon,
  Box,
  Flex,
  Loader,
  Paper,
  Text,
  Title,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import axios from "axios";
import { memo, useEffect, useState } from "react";
import { API_URL } from "../../services/url";
import { JobProps, RequestJobProps } from "../CreateJob/type";

type Props = {
  jobId: string | null;
  jobInfo: JobProps;
  onRefetch: () => void;
};

export const RequestedList: React.FC<Props> = memo(
  ({ jobId, jobInfo, onRefetch }) => {
    const userId = localStorage.getItem("userId");

    const [loading, setLoading] = useState(false);
    const [requestedJob, setRequestedJob] = useState<RequestJobProps[]>([]);

    const fetchRequestedList = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_URL}job-request/?job=${jobId}`);
        if (response.data.length > 0) {
          // console.log(response.data.some(
          //   (item: RequestJobProps) => item.status === "accepted"
          // ));

          if (
            !response.data.some(
              (item: RequestJobProps) => item.status === "accepted"
            )
          )
            setRequestedJob(
              response.data.filter(
                (item: RequestJobProps) => item.status === "pending"
              )
            );
        }
      } catch (error) {
        showNotification({
          title: "Error",
          message: "Failed to fetch request jobs",
          color: "red",
        });
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      fetchRequestedList();
    }, []);

    const handleRequest = async (id: string, isAccepted: boolean) => {
      try {
        let response;
        if (isAccepted)
          response = await axios.post(`${API_URL}job-request/${id}/accept/`);
        else response = await axios.post(`${API_URL}job-request/${id}/reject/`);
        console.log("🚀 ~ handleRequest ~ response:", response);
        if (response.data && response.data.message) {
          showNotification({
            title: `${isAccepted ? "Accepted" : "Rejected"}`,
            message: `Job Request ${isAccepted ? "Accepted" : "Rejected"}`,
            color: "green",
          });
          fetchRequestedList();
          onRefetch();
        }
      } catch (error) {
        showNotification({
          title: "Error",
          message: "Failed to accept/reject job request",
          color: "red",
        });
      }
    };

    const alertModal = (id: string, keyName: string) => {
      modals.openConfirmModal({
        title: `${keyName}`,
        centered: true,
        children: (
          <Text size="sm">{`Are you sure want to ${keyName} job request?`}</Text>
        ),
        labels: { confirm: `${keyName}`, cancel: `No` },
        confirmProps: { color: "primary" },
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          handleRequest(id, keyName === "accept");
        },
      });
    };

    const driverName = (item: RequestJobProps) => {
      if (
        item.requested_by_driver.first_name &&
        item.requested_by_driver.last_name
      ) {
        return `${item.requested_by_driver.first_name} ${item.requested_by_driver.last_name}`;
      }
      else if (item.requested_by_driver.first_name) {
        return item.requested_by_driver.first_name;
      }
      else if (item.requested_by_driver.last_name) {
        return item.requested_by_driver.last_name;
      }
      else return "Unknown Driver";
    };

    if (!jobInfo.public) return null;

    if (loading) return <Loader color="blue" />;

    if (!requestedJob.length) return null;

    return (
      <Box>
        <Title order={4} ta={"left"}>
          Requested Drivers
        </Title>
        <Text size="xs">{`${requestedJob.length} drivers`}</Text>
        {requestedJob.map((item, index) => {
          return (
            <Paper my={"md"} key={index} shadow="xs" withBorder>
              <Flex
                justify={"space-between"}
                p={"md"}
                align={"center"}
                w={"100%"}
              >
                <Text>{driverName(item)}</Text>
                <Flex>
                  <ActionIcon
                    variant="filled"
                    aria-label="Reject Job"
                    color="red"
                    radius={"xl"}
                    onClick={() => alertModal(item.id, "reject")}
                  >
                    <IconX
                      style={{ width: "70%", height: "70%" }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                  <ActionIcon
                    ml={"lg"}
                    variant="filled"
                    aria-label="Accept Job"
                    color="green"
                    radius={"xl"}
                    onClick={() => alertModal(item.id, "accept")}
                  >
                    <IconCheck
                      style={{ width: "70%", height: "70%" }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                </Flex>
              </Flex>
            </Paper>
          );
        })}
      </Box>
    );
  }
);
