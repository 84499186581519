import { Button, Flex } from "@mantine/core";
import { modals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { useState } from "react";
import { API_URL } from "../../services/url";

type Props = {
  jobId: string | null;
  onRefetch: () => void;
};

export const ReturnJob: React.FC<Props> = ({ jobId, onRefetch }) => {
  const urlParams = new URLSearchParams(window.location.search);

  const [loading, setLoading] = useState(false);

  const userId = localStorage.getItem("userId");

  const handleReturn = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${API_URL}jobs/${jobId}/cancel/${userId}`);
      if (res.data) {
        console.log("🚀 ~ handleReturn ~ res", res.data);
        onRefetch();
      }
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed To Return Job",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
    onRefetch();
  };

  // return job modal
  const returnConfirmationModal = () =>
    modals.openConfirmModal({
      title: "Are you sure want to retrun the job?",
      centered: true,
      // children: <Text size="sm">Are you sure want to Cancel your job?</Text>,
      labels: {
        confirm: "Yes, Return",
        cancel: "No",
      },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        handleReturn();
      },
      confirmProps: { color: "red" },
    });

  return (
    <Flex justify={"center"}>
      <Button
        w={"90%"}
        radius={"md"}
        size="md"
        color="red"
        variant="outline"
        mt={"lg"}
        disabled={loading}
        onClick={returnConfirmationModal}
        loaderProps={{ type: "oval" }}
      >
        {`Can't Full Fill Job`}
      </Button>
    </Flex>
  );
};
