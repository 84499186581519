// const serviceOfferedOptions = [
//   { value: 'sedan4', label: 'Sedan (4 pax)' },
//   { value: 'suv4', label: 'SUV (4 pax + luggage)' },
//   { value: 'luxury', label: 'Luxury (Long WB)' },
//   { value: 'whacc1', label: 'WH Access (1WH + 7 pax)' },
//   { value: 'whacc2', label: 'WH Access (2WH + 5 pax)' },
//   { value: 'maxi5-11', label: 'Maxi (5-11 pax)' },
//   { value: 'suv7', label: 'SUV (7 seater)' },
// ];

const serviceOfferedOptions = [
  { group: 'Sedan upto 4 persons', items: ['1 wheelchair', '2 wheelchair'] },
  { group: 'SUV', items: ['SUV'] },
  { group: 'Wheelchair', items: ['wheelchair'] },
  { group: 'Luxury upto 4 persons', items: ['Luxury upto 4 persons'] },
  {
    group: 'Maxi',
    items: [
      '5 persons',
      '6 persons',
      '7 persons',
      '8 persons',
      '9 persons',
      '10 persons',
      '11 persons',
    ],
  },
];



const carManufacturers = [
  { value: 'toyota', label: 'Toyota' },
  { value: 'honda', label: 'Honda' },
  { value: 'ford', label: 'Ford' },
  { value: 'chevrolet', label: 'Chevrolet' },
  { value: 'nissan', label: 'Nissan' },
  { value: 'bmw', label: 'BMW' },
  { value: 'mercedes', label: 'Mercedes-Benz' },
  { value: 'audi', label: 'Audi' },
  { value: 'volkswagen', label: 'Volkswagen' },
  { value: 'hyundai', label: 'Hyundai' },
  { value: 'kia', label: 'Kia' },
  { value: 'subaru', label: 'Subaru' },
  { value: 'mazda', label: 'Mazda' },
  { value: 'lexus', label: 'Lexus' },
  { value: 'infiniti', label: 'Infiniti' },
  { value: 'acura', label: 'Acura' },
  { value: 'volvo', label: 'Volvo' },
  { value: 'jeep', label: 'Jeep' },
  { value: 'landRover', label: 'Land Rover' },
  { value: 'porsche', label: 'Porsche' },
  { value: 'tesla', label: 'Tesla' },
  { value: 'jaguar', label: 'Jaguar' },
  { value: 'mini', label: 'MINI' },
  { value: 'fiat', label: 'Fiat' },
  { value: 'mitsubishi', label: 'Mitsubishi' },
];

const carModels = {
  toyota: ['Camry', 'RAV4', 'Corolla', 'Highlander', 'Tacoma'],
  honda: ['Civic', 'CR-V', 'Accord', 'Pilot', 'Odyssey'],
  ford: ['F-150', 'Mustang', 'Explorer', 'Escape', 'Expedition'],
  chevrolet: ['Silverado', 'Equinox', 'Traverse', 'Tahoe', 'Suburban'],
  nissan: ['Rogue', 'Altima', 'Sentra', 'Frontier', 'Murano'],
  bmw: ['3 Series', 'X5', '5 Series', 'X3', 'X7'],
  mercedes: ['C-Class', 'GLC', 'E-Class', 'GLE', 'S-Class'],
  audi: ['Q5', 'A4', 'Q7', 'A6', 'A8'],
  volkswagen: ['Jetta', 'Tiguan', 'Atlas', 'Golf', 'Passat'],
  hyundai: ['Tucson', 'Sonata', 'Elantra', 'Santa Fe', 'Palisade'],
  kia: ['Sportage', 'Forte', 'Sorento', 'Soul', 'Telluride'],
  subaru: ['Outback', 'Forester', 'Crosstrek', 'Ascent', 'Impreza'],
  mazda: ['CX-5', 'Mazda3', 'CX-9', 'Mazda6', 'MX-5 Miata'],
  lexus: ['RX', 'NX', 'ES', 'LX', 'IS'],
  infiniti: ['QX60', 'Q50', 'QX80', 'QX55', 'Q60'],
  acura: ['MDX', 'RDX', 'TLX', 'ILX', 'RLX'],
  volvo: ['XC90', 'XC60', 'XC40', 'S60', 'V60'],
  jeep: ['Wrangler', 'Grand Cherokee', 'Cherokee', 'Compass', 'Gladiator'],
  landRover: ['Range Rover', 'Range Rover Sport', 'Discovery', 'Defender', 'Evoque'],
  porsche: ['911', 'Cayenne', 'Macan', 'Panamera', 'Taycan'],
  tesla: ['Model 3', 'Model Y', 'Model S', 'Model X', 'Cybertruck'],
  jaguar: ['F-Pace', 'E-Pace', 'XF', 'XE', 'I-Pace'],
  mini: ['Cooper', 'Countryman', 'Clubman', 'Convertible', 'Hardtop'],
  fiat: ['500', '500X', '124 Spider', 'Panda', 'Tipo'],
  mitsubishi: ['Outlander', 'Eclipse Cross', 'Mirage', 'Outlander Sport', 'Outlander PHEV']
};


const yearsModel = [
  { label: '1993', value: '1993' },
];
  for (let year = 1994; year <= 2024; year++) {
    yearsModel.push({ value: year.toString(), label: year.toString() });
  }

const statesProvince =  [
  { value: 'New South Wales', label: 'New South Wales' },
  { value: 'Victoria', label: 'Victoria' },
  { value: 'Queensland', label: 'Queensland' },
  { value: 'South Australia', label: 'South Australia' },
  { value: 'Western Australia', label: 'Western Australia' },
  { value: 'Tasmania', label: 'Tasmania' },
  { value: 'Australian Capital Territory', label: 'Australian Capital Territory' },
  { value: 'Northern Territory', label: 'Northern Territory' },
];


export  { serviceOfferedOptions, carManufacturers , carModels , yearsModel, statesProvince };