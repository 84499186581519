import {
  Box,
  Container,
  Flex,
  LoadingOverlay,
  Text,
  Title,
  rem,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { IconArrowLeft } from "@tabler/icons-react";
import axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import { API_URL } from "../../services/url";
import checkUserId from "../../utils/checkuserid";
import { humenReadableTime } from "../../utils/datetime";

type NotificationProps = {
  id: string;
  notification_type: string;
  message: string;
  is_read: false;
  created_at: string;
  driver: string;
  job: string;
};

const Notifications = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [notificationList, setNotificationList] = useState<NotificationProps[]>(
    []
  );

  const [loading, setLoading] = useState(false);

  const driverId = localStorage.getItem("userId");

  useLayoutEffect(() => {
    checkUserId();
  }, []);

  const fetchJob = async () => {
    try {
      setLoading(true);
      const response = await axios.get<NotificationProps[]>(
        `${API_URL}drivers/${driverId}/notifications/`
      );
      setNotificationList(response.data);
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to fetch driver notifications",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJob();
  }, []);

  function goBack(): void {
    window.location.href = "/home";
  }

  return (
    <Container
      size={isMobile ? "xs" : "sm"}
      px="lg"
      py={isMobile ? "md" : "xl"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "self-start",
        textAlign: "left",
      }}
    >
      <LoadingOverlay visible={loading} />

      <Flex align={"center"} mb={"lg"} gap={"sm"} w={"100%"}>
        <IconArrowLeft
          style={{ width: rem(20), height: rem(20) }}
          stroke={1.5}
          onClick={goBack}
        />
        <Title order={2} fw={700}>
          Notifications
        </Title>
      </Flex>

      {notificationList &&
        notificationList.map((notification) => (
          <Box
            key={notification.id}
            w={"100%"}
            py={"md"}
            style={{
              // borderTop: "1px solid #e9e9e9",
              borderBottom: "1px solid #e9e9e9",
            }}
          >
            <Flex
              align={"center"}
              justify={"space-between"}
              w={"100%"}
              mb={"xs"}
            >
              <Text tt={"capitalize"} fw={600}>
                {notification.notification_type.replaceAll("_", " ")}
              </Text>
              <Text size="xs">
                {humenReadableTime(notification.created_at)}
              </Text>
            </Flex>
            <Text size="sm">{notification.message}</Text>
          </Box>
        ))}
    </Container>
  );
};

export default Notifications;
