import { Button, Container, Flex, Modal, Table, Text, Title } from '@mantine/core';
import { Driver_list_Props } from '../CreateJob/type';
import { formattedDateTime } from '../../utils/datetime';
import { useDisclosure } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { IconAlertCircle, IconCircleCheck, IconCircleX, IconX } from '@tabler/icons-react';
import axios from 'axios';
import { showNotification } from '@mantine/notifications';
import { API_URL } from '../../services/url';
import { modals } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';

type Earnings_Props = {
  total_earnings: number;
  last_month_earnings: number;
  last_year_earnings: number;
  daily_earnings: [];
};

const DriverTable = ({ driver_data, onRefetch }:{driver_data:any, onRefetch:()=>void}) => {
  const navigate = useNavigate();

  const [opened, { open, close }] = useDisclosure(false);

  const [profile, SetProfile] = useState<Driver_list_Props | undefined>();

  const [pause, setPause] = useState<string | null>(null);

  const [earings, setEarings] = useState<Earnings_Props>();

  const [openEarnigs, setOpenEarings] = useState(false);

  const handleView = (id:string) => {
    const data = driver_data.find((item:Driver_list_Props) => item.id === id);
    SetProfile(data);
    open();
  }

  const handleReport = async (id:string) =>  {
    navigate('/report', { state: { driverId: id } });
  }

  const rows = driver_data.map((item:Driver_list_Props) => (
    <Table.Tr key={item.id}>
      <Table.Td>{item.first_name}{item.last_name}</Table.Td>
      <Table.Td>{item.email}</Table.Td>
      <Table.Td>{item.license_plate_number}</Table.Td>
      <Table.Td>{item.application_status}</Table.Td>
      <Table.Td><Button color='black' onClick={() => handleView(item.id)} >View</Button></Table.Td>
      <Table.Td><Button color='black' onClick={() => handleReport(item.id)} >View</Button></Table.Td>
    </Table.Tr>
  ));

  const handle_pause = async ()=> {
    try {
      console.log(`${API_URL}drivers/${profile?.id}/${pause}/`);      
      const response = await axios.post(`${API_URL}drivers/${profile?.id}/${pause}/`);
      onRefetch()
      showNotification({
        title: "Success",
        message: `Driver ${pause} successfully`,
        color: "green",
      });
    } catch (error) {
      showNotification({
        title: "Error",
        message: `Failed to ${pause} Driver`,
        color: "red",
      });
    } finally {
      setPause(null);
    }
  }

  const delete_module = () => {
    modals.openConfirmModal({
      title: "Delete Account",
      centered: true,
      children: (
        <Text size="sm">{"Are you sure want to Delete this Account?"}</Text>
      ),
      labels: { confirm: "Delete", cancel: "No" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => pauseing('remove'),
    });
  };

  useEffect(() => {
    if(pause === null) return;
    handle_pause();
  },[pause]);

  const pauseing = (e:string) => {
    setPause(e)
    close();
  }

  return (
    <>
    <Modal size={'lg'} withCloseButton={false}  centered opened={opened} onClose={close}>
    {profile && <Container>
      <Flex justify={'space-between'}>
      <Title order={2} mb={'sm'}>Profile</Title>
      <IconX onClick={close} />
      </Flex>
      <Flex justify={'space-between'}>
      <Flex direction={'column'} gap={'lg'}>
        <Flex direction={'column'}>
          <Text c={'dimmed'}>First Name</Text>
          <Text size='lg'>{profile.first_name}</Text>
        </Flex>

        <Flex direction={'column'}>
          <Text c={'dimmed'}>Last Name</Text>
          <Text size='lg'>{profile.last_name}</Text>
        </Flex>

        <Flex direction={'column'}>
          <Text c={'dimmed'}>Email</Text>
          <Text size='lg'>{profile.email}</Text>
        </Flex>

        <Flex direction={'column'}>
          <Text c={'dimmed'}>Date of Birth</Text>
          <Text size='lg'>{profile.dob}</Text>
        </Flex>

        <Flex direction={'column'}>
          <Text c={'dimmed'}>Verify Code</Text>
          <Text size='lg'>{profile.verify_code}</Text>
        </Flex>

        <Flex direction={'column'}>
          <Text c={'dimmed'}>Application Status</Text>
          <Text size='lg'>{profile.application_status}</Text>
        </Flex>

      </Flex>
      
      <Flex direction={'column'} gap={'lg'}>
        <Flex direction={'column'}>
          <Text c={'dimmed'}>Phone No</Text>
          <Text size='lg'>{profile.phonenumber}</Text>
        </Flex>

        <Flex direction={'column'}>
          <Text c={'dimmed'}>Verified</Text>
          <Text size='lg'>{profile.is_verified ? <Flex gap={'xs'}><IconCircleCheck color='green' />Verified</Flex>:<Flex gap={'xs'}><IconCircleX color='red' />Not Verified</Flex>}</Text>
        </Flex>

        <Flex direction={'column'}>
          <Text c={'dimmed'}>Gender</Text>
          <Text size='lg'>{profile.gender}</Text>
        </Flex>

        <Flex direction={'column'}>
          <Text c={'dimmed'}>Created At</Text>
          <Text size='lg'>{formattedDateTime(profile.created_at)}</Text>
        </Flex>

        <Flex direction={'column'}>
          <Text c={'dimmed'}>Verify At</Text>
          <Text size='lg'>{formattedDateTime(profile.verified_at)}</Text>
        </Flex>

        <Flex direction={'column'}>
          <Text c={'dimmed'}>User Auth</Text>
          <Text size='lg'>{profile.user_auth}</Text>
        </Flex>
      </Flex>
      </Flex>

      <Flex pos={'relative'} w={'100%'} justify={'center'} gap={'lg'} mt={'md'}><Flex pos={'absolute'} left={50} h={'100%'} align={'center'}><IconAlertCircle color='orange' /></Flex>{profile.application_status === "paused" ? <Button w={'30%'} size='md' color='green' onClick={() => pauseing('unpause')}>Unfreeze Account</Button> :
        <Button w={'30%'} size='md' color='orange' onClick={() => pauseing('pause')}>Freeze Account</Button>}
        <Button w={'30%'} size='md' color='red' onClick={delete_module}>Delete Account</Button>
      </Flex>
    </Container>}      
    </Modal>

    <Modal size={'md'} title={<Title order={2}>Earnings</Title>} centered opened={openEarnigs} onClose={() => setOpenEarings(false)}>
      {earings && <Container>
        <Flex justify={'space-between'}>
          <Flex direction={'column'} gap={'lg'}>
            <Flex direction={'column'}>
              <Text c={'dimmed'}>Today Earnings</Text>
              <Text size='lg'>{earings.daily_earnings.reduce((acc, curr) => acc + curr, 0)}</Text>
            </Flex>

            <Flex direction={'column'}>
              <Text c={'dimmed'}>Last Month Earnings</Text>
              <Text size='lg'>{earings.last_month_earnings}</Text>
            </Flex>
          </Flex>

          <Flex direction={'column'} gap={'lg'}>
            <Flex direction={'column'}>
              <Text c={'dimmed'}>Last Year Earings</Text>
              <Text size='lg'>{earings.last_year_earnings}</Text>
            </Flex>

            <Flex direction={'column'}>
              <Text c={'dimmed'}>Total Earnings</Text>
              <Text size='lg'>{earings.total_earnings}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Container>}
    </Modal>
    <Table highlightOnHover withTableBorder>
      <Table.Thead>
        <Table.Tr>
          <Table.Th ta={'center'}>Name</Table.Th>
          <Table.Th ta={'center'}>Mail ID</Table.Th>
          <Table.Th ta={'center'}>Vehicle No</Table.Th>
          <Table.Th ta={'center'}>Application Status</Table.Th>
          <Table.Th ta={'center'}>Profile</Table.Th>
          <Table.Th ta={'center'}>Report</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
    </>
  );
};

export default DriverTable;