import { Button, Flex, Image, LoadingOverlay, Overlay, PasswordInput, Stack, Text, TextInput, Title } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import checkAdmin from './checkAdmin'
import axios from 'axios'
import { API_URL } from '../../services/url'
import { useMediaQuery } from '@mantine/hooks'

export const AdminLogin = () => {
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const ismobile = useMediaQuery('(max-width: 600px)')

    type AdminLoginResponse = {
        success: boolean
        response: string
        admin_id?: number
    }

    console.log(`${API_URL}signupview/`);

    const adminLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (userName && password) {
            try {
                setError("");
                setLoading(true);
                const response = await axios.post<AdminLoginResponse>(`${API_URL}signinview/`, {
                    admin: userName,
                    password: password,
                });

                console.log("🚀 ~ Admin login response ~ response:", response);

                if (response.data.success === true) {
                    const adminId = response.data.admin_id;
                    localStorage.setItem("adminId", adminId?.toString() || "");
                    window.location.href = "/dashboard";
                } else {
                    setError(response.data.response);
                }
            } catch (error) {
                console.error("Admin Login failed", error);
                setError("An error occurred during Admin Login");
            } finally {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        checkAdmin()
    }, [])
    
    return (
        <Overlay>
            <LoadingOverlay pos={'fixed'} visible={loading} />
            <Flex h={'100vh'} justify="center" align="center">
                <Stack bg={'white'} w={ismobile ? '90%' : '30%'} style={{borderRadius:'10px'}}>
                    <form onSubmit={adminLogin}>
                        <Flex direction={'column'} align={'center'} p={'lg'} gap={'xl'} justify="center">
                            <Flex direction={"column"} align={"center"} gap={"xs"}>
                                <Image 
                                  src={'https://i.imgur.com/Ka0ORrE.png'}
                                />
                                <Title order={1} style={{ fontSize: ismobile ? "1.5rem" : "3rem" }}>
                                    Kabbie
                                </Title>
                                <Text size="sm">Ride as one community.</Text>
                            </Flex>
                            <TextInput
                                w={'80%'}
                                ta={'left'}
                                label="Username"
                                placeholder="Enter your username"
                                type="text"
                                size="md"
                                required
                                labelProps={{ style: { alignSelf: "flex-start" } }}
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                onFocus={() => setError("")}
                            />
                            <PasswordInput
                                w={'80%'}
                                ta={'left'}
                                label="Password"
                                placeholder="Enter your password"
                                required
                                size="md"
                                labelProps={{ style: { alignSelf: "flex-start" } }}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onFocus={() => setError("")}
                            />
                            {error && (
                                <Text c="red" size="sm">
                                    {error}
                                </Text>
                            )}
                            <Button w={'80%'} variant="filled" color="black" type="submit">
                                Login
                            </Button>
                        </Flex>
                    </form>
                </Stack>
            </Flex>
        </Overlay>
    )
}