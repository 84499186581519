import { ActionIcon, Avatar, Flex, Loader, Modal, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconCapture, IconCheck, IconPlus, IconX } from "@tabler/icons-react";
import { memo, useRef, useState } from "react";

type Props = {
  onCapture: (file: File) => void;
  uploadingImage: boolean;
  showTitle?: boolean;
};

export const CameraAction: React.FC<Props> = memo(
  ({ onCapture, uploadingImage, showTitle }) => {
    const [opened, { open, close }] = useDisclosure(false);
    const [captured, setCaptureImage] = useState(false);

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const [jobImage, setImageURL] = useState<string>("");

    const startCamera = () => {
      open();
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const constraints = {
          video: {
            facingMode: "environment", // This will prefer the back camera on mobile devices
          },
        };
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          let video = videoRef.current;
          if (video) {
            (video as HTMLVideoElement).srcObject = stream;
            video.play();
          }
        });
      }
    };

    const captureImage = () => {
      console.log(canvasRef);
      if (canvasRef.current) {
        let context = canvasRef.current.getContext("2d");
        let video = videoRef.current;
        if (context && video) {
          context.drawImage(video, 0, 0, window.innerWidth, window.innerHeight);
          setCaptureImage(true);
        }
      }
    };

    const onStopVideo = () => {
      if (videoRef.current && videoRef.current.srcObject) {
        let stream = videoRef.current.srcObject as MediaStream;
        let tracks = stream.getTracks();

        tracks.forEach(function (track) {
          track.stop();
        });

        videoRef.current.srcObject = null;
      }
    };

    const convertCanvasIntoFile = () => {
      if (canvasRef.current) {
        canvasRef.current.toBlob(
          (blob) => {
            if (blob) {
              const file = new File([blob], "photo.jpg", {
                type: "image/jpeg",
                lastModified: Date.now(),
              });
              console.log(file);

              onCapture(file);
              onStopVideo();
              close();
              // Do something with the file
              // For example, you can send it to the server
              const url = URL.createObjectURL(file);
              setImageURL(url);
            }
          },
          "image/jpeg",
          1
        );
      }
    };

    return (
      <>
        {showTitle && <Text>{`Upload Invoice Image`}</Text>}
        <Avatar size={"xl"} onClick={startCamera} src={jobImage}>
          {uploadingImage ? (
            <Loader color="blue" />
          ) : (
            <IconPlus size={20} style={{ marginRight: "5px" }} />
          )}
        </Avatar>
        <Modal
          opened={opened}
          withCloseButton={false}
          onClose={close}
          // title="This is a fullscreen modal"
          fullScreen
          radius={0}
          // p={0}
          // m={0}
          styles={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
          transitionProps={{ transition: "fade", duration: 200 }}
        >
          <div style={{ zIndex: "100" }}>
            <canvas
              ref={canvasRef}
              width={window.innerWidth}
              height={window.innerHeight}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                opacity: captured ? 1 : 0,
                zIndex: captured ? 100 : -1,
              }}
            />

            <video
              ref={videoRef}
              width={window.innerWidth}
              height={window.innerHeight}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                opacity: captured ? 0 : 1,
                zIndex: captured ? -1 : 100,
              }}
            />

            {!captured && (
              <ActionIcon
                style={{
                  position: "absolute",
                  bottom: "50px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 101,
                }}
                variant="filled"
                aria-label="Settings"
                onClick={captureImage}
              >
                <IconCapture
                  style={{ width: "100%", height: "100%" }}
                  stroke={1.5}
                />
              </ActionIcon>
            )}

            {captured && (
              <Flex
                gap={"lg"}
                pos={"absolute"}
                bottom={"50px"}
                left={"50%"}
                style={{ transform: "translateX(-50%)", zIndex: 101 }}
              >
                <ActionIcon
                  variant="filled"
                  aria-label="Settings"
                  color="red"
                  onClick={() => setCaptureImage(false)}
                >
                  <IconX
                    style={{ width: "100%", height: "100%" }}
                    stroke={1.5}
                  />
                </ActionIcon>
                <ActionIcon
                  variant="filled"
                  aria-label="Settings"
                  color="green"
                  onClick={convertCanvasIntoFile}
                >
                  <IconCheck
                    style={{ width: "100%", height: "100%" }}
                    stroke={1.5}
                  />
                </ActionIcon>
              </Flex>
            )}
          </div>
        </Modal>
      </>
    );
  }
);
