import { Container, Stepper, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconLicense } from "@tabler/icons-react";
import { useLayoutEffect, useState } from "react";
import checkUserId from "../../utils/checkuserid";
import AddressDetails from "../AddressDetails";
import DriverLicenseDetails from "../DriverLicenseDetails";
import PersonalDetails from "../PersonalDetails";
import VehicleDetails from "../VehicleDetails";

const OnBoarding = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [activeStep, setActiveStep] = useState(0);

  useLayoutEffect(() => {
    checkUserId();
  }, []);

  const nextStep = () =>
    setActiveStep((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActiveStep((current) => (current > 0 ? current - 1 : current));

  const gotoHome = () => (window.location.href = "/home");

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <PersonalDetails onNext={nextStep} />;
      case 1:
        return <AddressDetails onNext={nextStep} />;
      case 2:
        return <DriverLicenseDetails onNext={nextStep} />;
      case 3:
        return <VehicleDetails onNext={gotoHome} />;
      case 4:
        return <IconLicense />;
      default:
        return null;
    }
  };

  return (
    <Container
      size={isMobile ? "xs" : "sm"}
      // px="lg"
      py={isMobile ? "md" : "xl"}
      bg={"bg"}
    >
      <Title
        order={1}
        style={{ fontSize: "2.5rem", marginBottom: "20px" }}
        mb="md"
        className="gradient-text  gradient-animation"
      >
        Kabbie
      </Title>
      <br />
      <Stepper
        active={activeStep}
        onStepClick={setActiveStep}
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        allowNextStepsSelect={false}
      >
        <Stepper.Step label="Step 1" description="Personal Details">
          {renderStepContent()}
        </Stepper.Step>
        <Stepper.Step label="Step 2" description="Address Details">
          {renderStepContent()}
        </Stepper.Step>
        <Stepper.Step label="Step 3" description="License Details">
          {renderStepContent()}
        </Stepper.Step>
        <Stepper.Step label="Step 4" description="Vehicle Details">
          {renderStepContent()}
        </Stepper.Step>
      </Stepper>
    </Container>
  );
};

export default OnBoarding;
