import { Button, Flex } from "@mantine/core";
import { modals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { memo, useState } from "react";
import { API_URL } from "../../services/url";
import { JobProps } from "../CreateJob/type";
import { IconRestore } from "@tabler/icons-react";

type Props = {
  jobId: string | null;
  jobInfo: JobProps;
  onRefetch: () => void;
};

export const ResetJob: React.FC<Props> = memo(
  ({ jobId, jobInfo, onRefetch }) => {
    const [loading, setLoading] = useState(false);

    //Reset Job
    const handleReset = async () => {
      console.log("Reset Job");
      try {
        setLoading(true);
        const cancelres = await axios.get(`${API_URL}reset-job/${jobId}/`);
        console.log("🚀 ~ handleUpdateJob ~ cancelres:", cancelres);
        if (cancelres.data) {
          if (jobInfo) onRefetch();
        } else
          showNotification({
            title: "Error",
            message: "Failed to cancel job",
            color: "red",
          });
      } catch (error) {
        console.log("🚀 ~ handleUpdateJob ~ error:", error);
        showNotification({
          title: "Error",
          message: "Failed to cancel job",
          color: "red",
        });
      } finally {
        setLoading(false);
      }
    };

    //Reset modal
    const resetModal = () =>
      modals.openConfirmModal({
        title: "Are you sure want to reset your job?",
        centered: true,
        // children: <Text size="sm">Are you sure want to Cancel your job?</Text>,
        labels: {
          confirm: "Yes, reset",
          cancel: "No",
        },
        onCancel: () => console.log("reset"),
        onConfirm: () => {
          handleReset();
        },
        confirmProps: { color: "primary" },
      });

    if (!jobId) return null;

    return (
      // <Button
      //   fullWidth
      //   size="md"
      //   variant="outline"
      //   color="primary"
      //   mt={"lg"}
      //   disabled={loading}
      //   onClick={resetModal}
      //   style={{ height: "50px" }}
      //   loaderProps={{ type: "oval" }}
      // >
      //   Reset Job
      // </Button>
      <Flex justify={"center"} align={"center"}>
        {!loading && <IconRestore stroke={3} onClick={resetModal} />}
      </Flex>
    );
  }
);
