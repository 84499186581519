import { Box, Button, Flex, Modal, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { memo, useState } from "react";
import { API_URL } from "../../services/url";
import { JobProps, TripProps } from "../CreateJob/type";
import { InviteDriver } from "./invitedriver";

type Props = {
  jobId: string | null;
  jobInfo: JobProps;
  tripInfo: TripProps | null | undefined;
  onRefetch: () => void;
};

export const MakePublicAndInviteUser: React.FC<Props> = memo(
  ({ jobId, jobInfo, onRefetch, tripInfo }) => {
    const userId = localStorage.getItem("userId");

    const [loading, setLoading] = useState(false);

    const [opened, { open, close }] = useDisclosure(false);

    const handleMakePostPublic = async () => {
      try {
        setLoading(true);
        const publicJob = Object.assign({}, jobInfo, {
          posted_by: jobInfo.posted_by.id,
          public: true,
        });
        const res = await axios.put(
          `${API_URL}job-details/${jobId}/`,
          publicJob
        );
        console.log("🚀 ~ handleMakePostPublic ~ res:", res.data);
        if (res.data && res.data.id) {
          setLoading(false);
          showNotification({
            title: "Success",
            message: "Job post made public",
            color: "green",
          });
          onRefetch();
        }
      } catch (error) {
        setLoading(false);
        showNotification({
          title: "Error",
          message: "Failed to make job public",
          color: "red",
        });
      }
    };

    const alertModal = () => {
      modals.openConfirmModal({
        title: "Make Post Public",
        centered: true,
        children: (
          <Text size="sm">{"Are you sure want to make post public?"}</Text>
        ),
        labels: { confirm: "Make Public", cancel: "No don't make public it" },
        confirmProps: { color: "primary" },
        onCancel: () => console.log("Cancel"),
        onConfirm: () => handleMakePostPublic(),
      });
    };

    if (jobInfo.posted_by.id !== userId) return null;

    return (
      <Box>
        <Modal opened={opened} centered onClose={close} title="Invite Driver">
          <InviteDriver post_by={jobInfo.posted_by.id} />
        </Modal>

        <Flex justify={"center"}>
          <Flex justify={"center"} w={"90%"} align={"center"} gap={"md"}>
            {!jobInfo.public && (
              <Button
                fullWidth
                size="md"
                radius={"md"}
                color="primary"
                variant="outline"
                mt={"lg"}
                disabled={loading}
                onClick={alertModal}
                loading={loading}
                // style={{ height: "50px" }}
                loaderProps={{ type: "oval" }}
              >
                Make Job Public
              </Button>
            )}

            <Button
              fullWidth
              size="md"
              radius={"md"}
              color="primary"
              variant="outline"
              mt={"lg"}
              disabled={loading}
              onClick={open}
              loading={loading}
              // style={{ height: "50px" }}
              loaderProps={{ type: "oval" }}
            >
              Invite Driver
            </Button>
          </Flex>
        </Flex>
      </Box>
    );
  }
);
