import { useEffect, useState } from 'react';
import { Button, Container, Flex, LoadingOverlay, rem, Select, Tabs, Text, TextInput, Title } from '@mantine/core';
import { IconArrowLeft, IconCar, IconBriefcase, IconX, IconSearch, IconLogout } from '@tabler/icons-react';
import axios from 'axios';
import { showNotification } from '@mantine/notifications';
import { API_URL } from '../../services/url';
import { Driver_list_Props, Job_Summary_Props, Trip_Summary_Props, TripList_Props } from '../CreateJob/type';
import DriverTable from './driverTable';
import { TripTable } from './tripTable';
import { CarSeaterList, JobFilterList, JobSortByList, ReportFilterList, ReportSortList } from '../CreateJob/constants';
import checkAdmin from '../AdminLogin/checkAdmin';
import { modals } from '@mantine/modals';
import { JobTable } from './jobTable';

export const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [tripdata, setTripdata] = useState<Trip_Summary_Props>();
  const [jobdata, setJobdata] = useState<Job_Summary_Props>();
  const [driverlist, setDriverlist] = useState<Driver_list_Props[]>([]);
  const [triplist, setTriplist] = useState<TripList_Props>(); 
  const [joblist, setJoblist] = useState();
   
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [searchDriver, setSearchDriver] = useState('');

  const [search, setSearch] = useState("");
  const [filterBy, setFilterBy] = useState<string | null>("");
  const [sortby, setSortby] = useState<string | null>("-end_time");

  const [jobSearch, setJobSearch] = useState("");
  const [jobFilterBy, setJobFilterBy] = useState<string | null>("");
  const [jobStatus, setJobStatus] = useState<string | null>("");
  const [jobSortby, setJobSortby] = useState<string | null>("-pickup_at");

  useEffect(() => {
    checkAdmin();
  }, []);  

  const fetchTripSummary = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}dashboard/trip-summary/`);
      setTripdata(response.data);
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to fetch Trip Summary",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchJobSummary = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}dashboard/job-summary/`);
      setJobdata(response.data);
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to fetch Job Summary",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchDriverTable = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}drivers-list/?search=${searchDriver}`);
      setDriverlist(response.data);
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to fetch Drivers Details",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDriverTable();
  }, [searchDriver]);

  const fetchJobTable = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_URL}job-detailslistings/?search=${jobSearch}&ordering=${jobSortby}&services_required=${jobFilterBy}&status=${jobStatus}`
      );    
      if (response.data.length === 0) {
        showNotification({
          title: "No Data",
          message: "No job match with the search",
          color: "black",
        });
      } else
        setJoblist(response.data);
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to fetch job details",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobTable();
  }, [jobSearch, jobFilterBy, jobSortby, jobStatus]);

  const fetchTripTable = async (url: string = `${API_URL}trip-lists/?booking_id=${search}&ordering=${sortby}&status=${filterBy}`) => {
    console.log(`${API_URL}trip-lists/?booking_id=${search}&ordering=${sortby}&status=${filterBy}`);    
    try {
      setLoading(true);
      const response = await axios.get(url);
      setTriplist(response.data);
      setCurrentPage(Number(new URL(url).searchParams.get('page')) || 1);
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to fetch Trip Details",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTripTable();
  }, [search, filterBy, sortby]);

  useEffect(() => {
    fetchTripSummary();
    fetchJobSummary();
    fetchDriverTable();
    fetchTripTable();
    fetchJobTable();
  }, []);

  const changeNextTrip = () => {
    if (triplist?.next) {
      fetchTripTable(triplist.next);
    }
  };

  const changePreviousTrip = () => {
    if (triplist?.previous) {
      fetchTripTable(triplist.previous);
    }
  };

  const logoutmodal = () =>
    modals.openConfirmModal({
      title: "Logout",
      centered: true,
      children: <Text size="sm">Are you sure want to Logout?</Text>,
      labels: {
        confirm: "Logout",
        cancel: "Cancel",
      },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        handlelogout();
      },
      confirmProps: { color: "red" },
    });

  const handlelogout = () => {
    localStorage.removeItem("adminId");
    window.location.href = "/admin/login";
  };

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(currentPage * itemsPerPage, triplist?.count || 0);

  function HeaderCom() {      
    return (
      <Flex justify={"space-between"} align={"center"} w={"100%"}>
        <IconArrowLeft
          style={{ width: rem(30), height: rem(30)}}
          onClick={() => window.location.href=("/home")}
        />
        <Title order={2} ta={"center"} w={"100%"} fw={700}>
          {`Kabbie`}
        </Title>
        <IconLogout
          style={{ width: rem(30), height: rem(30)}}
          onClick={logoutmodal}
        />
      </Flex>
    );
  }

  return (
    <Container py={'xl'} bg={'bg'} fluid>
      <HeaderCom />
      <LoadingOverlay pos={'fixed'} top={0} left={0} visible={loading} />
      
      <Flex align={'center'} direction={'column'} my={'lg'}>
        <Title order={3} ta={'left'}>Trip Summary</Title>
        <Flex gap={'1%'} justify={'center'} w={'100%'}>
          <Flex w={'25%'} h={'100px'} direction={'column'} justify={'center'} p={'sm'} style={{borderRadius:'10px', overflow:'auto'}} bd={'1px solid black'} >
            <Flex align="center" mb={5}>
              <IconCar size={20} style={{marginRight: '5px', color: '#777'}} />
              <Title order={4} ta={'left'} c="dimmed">Total Trips</Title>
            </Flex>
            <Title order={2} ta={'left'}>{tripdata?.total_trips}</Title>
          </Flex>
          <Flex w={'25%'} h={'100px'} direction={'column'} justify={'center'} p={'sm'} style={{borderRadius:'10px', overflow:'auto'}} bd={'1px solid black'} >
            <Flex align="center" mb={5}>
              <IconCar size={20} style={{marginRight: '5px', color: '#777'}} />
              <Title order={4} ta={'left'} c="dimmed">Ongoing Trips</Title>
            </Flex>
            <Title order={2} ta={'left'}>{tripdata?.ongoing_trips}</Title>
          </Flex>
          <Flex w={'25%'} h={'100px'} direction={'column'} justify={'center'} p={'sm'} style={{borderRadius:'10px', overflow:'auto'}} bd={'1px solid black'} >
            <Flex align="center" mb={5}>
              <IconX size={20} style={{marginRight: '5px', color: '#777'}} />
              <Title order={4} ta={'left'} c="dimmed">Cancelled Trips</Title>
            </Flex>
            <Title order={2} ta={'left'}>{tripdata?.cancelled_trips}</Title>
          </Flex>
        </Flex>
      </Flex>

      <Flex align={'center'} direction={'column'} my={'lg'}>
        <Title order={3} ta={'left'}>Job Summary</Title>
        <Flex gap={'1%'} w={'100%'} justify={'center'} >
          <Flex w={'25%'} h={'100px'} direction={'column'} justify={'center'} p={'sm'} style={{borderRadius:'10px', overflow:'auto'}} bd={'1px solid black'} >
            <Flex align="center" mb={5}>
              <IconBriefcase size={20} style={{marginRight: '5px', color: '#777'}} />
              <Title order={4} ta={'left'} c="dimmed">Total Jobs</Title>
            </Flex>
            <Title order={2} ta={'left'}>{jobdata?.total_jobs}</Title>
          </Flex>
          <Flex w={'25%'} h={'100px'} direction={'column'} justify={'center'} p={'sm'} style={{borderRadius:'10px', overflow:'auto'}} bd={'1px solid black'} >
            <Flex align="center" mb={5}>
              <IconBriefcase size={20} style={{marginRight: '5px', color: '#777'}} />
              <Title order={4} ta={'left'} c="dimmed">Pending Jobs</Title>
            </Flex>
            <Title order={2} ta={'left'}>{jobdata?.pending_jobs}</Title>
          </Flex>
          <Flex w={'25%'} h={'100px'} direction={'column'} justify={'center'} p={'sm'} style={{borderRadius:'10px', overflow:'auto'}} bd={'1px solid black'} >
            <Flex align="center" mb={5}>
              <IconX size={20} style={{marginRight: '5px', color: '#777'}} />
              <Title order={4} ta={'left'} c="dimmed">Cancelled Jobs</Title>
            </Flex>
            <Title order={2} ta={'left'} >{jobdata?.cancelled_jobs}</Title>
          </Flex>
        </Flex>
      </Flex>

      <Flex w={"100%"} mt={"md"} justify={'center'}>
        <Tabs w={'90%'} color='black' defaultValue="driverlist" variant="pills">
          <Tabs.List justify='center'>
            <Tabs.Tab w={'25%'} size={"md"} value="driverlist" bd={'1px solid black'}>
              Drivers List
            </Tabs.Tab>
            <Tabs.Tab w={'25%'} size={"md"} value="joblist" bd={'1px solid black'}>
              Job List
            </Tabs.Tab>
            <Tabs.Tab w={'25%'} size={"md"} value="triplist" bd={'1px solid black'}>
              Trips List
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="driverlist" pt="md" style={{overflow:"auto"}} >
            <Flex justify={'center'} mb={'md'}>
                <TextInput
                  value={searchDriver}
                  placeholder="Search Mail ID, license plate"
                  onChange={(event) => setSearchDriver(event.currentTarget.value)}
                  w={"60%"}
                  rightSectionPointerEvents="visible"
                  rightSection={
                    searchDriver === "" ? (
                      <IconSearch style={{ width: rem(20), height: rem(20) }} />
                    ) : (
                      <IconX
                        style={{ width: rem(20), height: rem(20) }}
                        onClick={() => setSearchDriver("")}
                      />
                    )
                  }
                  labelProps={{ style: { alignSelf: "flex-start" } }}
                />
            </Flex>
            <Flex style={{overflow:'auto'}}>
            <DriverTable driver_data={driverlist} onRefetch={fetchDriverTable} />
            </Flex>
          </Tabs.Panel>

          <Tabs.Panel value="joblist" pt="md">
            <Flex align={'center'} direction={'column'}>
              <TextInput
                value={jobSearch}
                placeholder="Search Booking ID, Location, Suburb"
                onChange={(event) => setJobSearch(event.currentTarget.value)}
                w={"60%"}
                rightSectionPointerEvents="visible"
                rightSection={
                  jobSearch === "" ? (
                    <IconSearch style={{ width: rem(20), height: rem(20) }} />
                  ) : (
                    <IconX
                      style={{ width: rem(20), height: rem(20) }}
                      onClick={() => setJobSearch("")}
                    />
                  )
                }
                labelProps={{ style: { alignSelf: "flex-start" } }}
              />

              <Flex justify={"space-between"} align={"center"} w={"60%"} my="md" gap={'1%'}>
                <Select
                  w={"33%"}
                  placeholder="Filter Jobs"
                  data={CarSeaterList}
                  value={jobFilterBy}
                  clearable
                  onClear={() => setJobFilterBy("")}
                  onChange={(value) => setJobFilterBy(value)}
                />
                <Select
                  w={"33%"}
                  placeholder="Filter By Status"
                  data={JobFilterList}
                  value={jobStatus}
                  clearable
                  onClear={() => setJobStatus("")}
                  onChange={(value) => setJobStatus(value)}
                />
                <Select
                  w={"33%"}
                  placeholder="Sort By"
                  data={JobSortByList}
                  value={jobSortby}
                  clearable
                  onClear={() => setJobSortby("")}
                  onChange={(value) => setJobSortby(value)}
                />
              </Flex>
            </Flex>
            
            <Flex style={{overflow:"auto"}} >
              <JobTable jobdata={joblist} onRefetch={fetchJobTable} />
            </Flex>
          </Tabs.Panel>

          <Tabs.Panel value="triplist"  pt="md">
            <Flex align={'center'} direction={'column'}>
              <TextInput
                value={search}
                placeholder="Search Booking ID"
                onChange={(event) => setSearch(event.currentTarget.value)}
                w={"60%"}
                rightSectionPointerEvents="visible"
                rightSection={
                  search === "" ? (
                    <IconSearch style={{ width: rem(20), height: rem(20) }} />
                  ) : (
                    <IconX
                      style={{ width: rem(20), height: rem(20) }}
                      onClick={() => setSearch("")}
                    />
                  )
                }
                labelProps={{ style: { alignSelf: "flex-start" } }}
              />

              <Flex justify={"center"} align={"center"} w={"100%"} my="md" gap={'2%'}>
                <Select
                  w={"29%"}
                  placeholder="Filter Jobs"
                  data={ReportFilterList}
                  value={filterBy}
                  clearable
                  onClear={() => setFilterBy("")}
                  onChange={(value) => setFilterBy(value)}
                />
                <Select
                  w={"29%"}
                  placeholder="Sort By"
                  data={ReportSortList}
                  value={sortby}
                  clearable
                  onClear={() => setSortby("")}
                  onChange={(value) => setSortby(value)}
                />
              </Flex>
            </Flex>
            <Flex style={{overflow:"auto"}} >
            <TripTable trip_data={triplist?.results}/>
            </Flex>
            <Flex w={'100%'} justify={'center'} align={'center'} gap={'lg'} my={'xs'} >
              {triplist?.previous !== null && (
                <Button onClick={changePreviousTrip} variant='outline' color='black'>
                  Previous
                </Button>
              )}
              <Text>{`${startIndex} - ${endIndex} of ${triplist?.count || 0}`}</Text>
              {triplist?.next !== null && (
                <Button onClick={changeNextTrip} variant='outline' color='black'>
                  Next
                </Button>
              )}
            </Flex>
          </Tabs.Panel>
        </Tabs>
      </Flex>
    </Container>
  );
};