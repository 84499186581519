import { Button, Flex, Paper, Text, rem, Loader } from "@mantine/core"; // replace 'your-ui-library' with the actual library you're using
import { IconCalendar, IconCheck, IconMapPin, IconWheelchair, IconCar } from "@tabler/icons-react";
import { memo, useState } from "react";
import { formattedDateTime, humenReadableTime } from "../../utils/datetime";
import { JobInfoProps } from "../CreateJob/type";
import { modals } from "@mantine/modals";
import axios from "axios";
import { API_URL } from "../../services/url";
import { showNotification } from "@mantine/notifications";
import { AcceptOrDeclineJob } from "../JobInfo/acceptordeclinejob";

const style = { width: rem(16), height: rem(16) };

const LocationIcon = <IconMapPin style={style} />;

const CalendarIcon = <IconCalendar style={style} />;

const CarIcon = <IconCar style={style} />;

const WheelChairIcon = <IconWheelchair style={style} color="blue" />;

const AllJobs = memo(
  ({
    job,
    driverId,
    requestlist,
  }: {
    job: JobInfoProps;
    driverId: string | null;
    requestlist: string[] | undefined;
  }) => {
    // replace 'your-driver-id' with the actual driver id

    const [loading, setLoading] = useState(false);

    const userId = localStorage.getItem("userId");

    // requesting job
    const alertModal = (e: any) => {
      e.stopPropagation();
      modals.openConfirmModal({
        title: "Request Job",
        centered: true,
        children: (
          <Text size="sm">{"Are you sure want to request this job?"}</Text>
        ),
        labels: { confirm: "Request Job", cancel: "No don't request it" },
        confirmProps: { color: "primary" },
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          handleRequestJob();
        },
      });
    };

    const handleRequestJob = async () => {
      try {
        setLoading(true);
        const res = await axios.post(`${API_URL}job-request/`, {
          status: "pending",
          job: job?.id,
          requested_by_driver: userId,
        });
        if (res.data && res.data.id) {
          showNotification({
            title: "Success",
            message: "Job requested successfully",
            color: "green",
          });
          requestlist?.push(job.id);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    // requested list

    
    return (
      <Paper
        key={job.id}
        withBorder
        mt={"sm"}
        radius={0}
        pos={"relative"}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          borderColor: "transparent",
          borderLeftWidth: 4,
          borderLeftColor: "#2B2B2B",
          backgroundColor: "rgba(9, 31, 91, 0.08)",
          borderRadius: "0.5rem",
          width: "100%",
        }}
        // onClick={() => (window.location.href = `/job?id=${job.id}`)}
      >
        <Flex pos={'absolute'} top={'45%'} left={'45%'}>
        {loading &&<Loader color="blue" />}
        </Flex>

        {/* date and time info */}
        <Flex
          direction={"row"}
          align={"center"}
          justify={"space-between"}
          gap={"xs"}
        >
          {/* Left Section: Date and Time */}
          <Flex
            direction={"row"}
            align={"center"}
            justify={"start"}
            gap={6}
            w={"50%"}
          >
            {CalendarIcon}
            <Text size="sm" fw={400}>
              {formattedDateTime(job.pickup_at)}
            </Text>
          </Flex>

          {/* Right Section: Booking ID, Distance, Amount */}
          <Flex
            direction={"row"}
            align={"center"}
            justify={"flex-end"}
            gap={"md"}
            w={"50%"}
          >
            <Flex align={"center"} mr={6}>
              <Text size="xs">{`ID :`}</Text>
              <Text size="lg" p={0} fw={700}>
                {job.booking_id}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        {/* Pickup and Drop Address */}
        <Flex justify={"space-between"} align={"center"} mt={"sm"}>
          {/* Left Section: Pickup and Drop Address */}
          <Flex direction={"column"}>
            <Flex direction={"row"} align={"center"} gap={6} mb={"md"}>
              {LocationIcon}
              <Text size="sm" fw={700}>
                {`${job.pickupLoc}`}
              </Text>
              <Text size="xs" fw={400}>
                {`(Pickup)`}
              </Text>
            </Flex>

            <Flex direction={"row"} align={"center"} gap={6}>
              {LocationIcon}
              <Text size="sm" fw={700}>
                {`${job.dropLoc}`}
              </Text>
              <Text size="xs" fw={400}>
                {`(Drop)`}
              </Text>
            </Flex>
          </Flex>

          {/* Right Section: Request Button */}
          {job.posted_by !== userId ? (
            <Flex>
              {requestlist &&
              requestlist.length > 0 &&
              requestlist?.includes(job.id) ? (
                <Button
                  radius={"md"}
                  size="xs"
                  color="gray"
                  leftSection={<IconCheck color="gray" size={16} />}
                  variant="outline"
                  disabled={true}
                >
                  Requested
                </Button>
              ) : (
                <Flex>
                <Button
                  radius={"md"}
                  size="xs"
                  variant="gradient"
                  gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
                  disabled={loading}
                  onClick={(e) => alertModal(e)}
                  loading={loading}
                  loaderProps={{ type: "oval" }}
                >
                  Request Job
                </Button>
                </Flex>
              )}
            </Flex>
          ) : (
            <Button
                  radius={"md"}
                  size="xs"
                  color="gray"                 
                  variant="outline"
                  disabled={true}  
                >
                  Posted by you
                </Button>
          )}
        </Flex>

        {/* Services Required and Posted Time */}
        <Flex
          direction={"row"}
          align={"center"}
          justify={"space-between"}
          gap={"xs"}
          mt={"md"}
        >
            <Flex align={"center"} gap={"xs"}>
              {job.services_required.includes("wheel")
                ? WheelChairIcon
                : CarIcon}
              <Text
                size={"sm"}
                fw={600}
                style={{
                  color: job.services_required.includes("wheel")
                    ? "blue"
                    : "black",
                }}
              >
                {job.services_required}
              </Text>
            </Flex>

          <Text size="xs" fw={400}>
            {humenReadableTime(job.posted_at)}
          </Text>
        </Flex>
        <Flex
          direction={"row"}
          align={"center"}
          justify={"space-between"}
          gap={"xs"}
          mt={"md"}
        >
          {job.distance !== 0 && (
            <Flex direction={"row"} align={"center"} gap={6}>
              {LocationIcon}
              <Text size="sm" fw={700}>{`${job.distance} Km`}</Text>
            </Flex>
          )}
          <Text size="sm" fw={700}>{`${
            job.amount ? "$ " + job.amount : ""
          }`}</Text>
        </Flex>
      </Paper>
    );
  }
);

export default AllJobs;
