import {
  Button,
  Container,
  Flex,
  LoadingOverlay,
  Select,
  Stack,
  TextInput,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { useState } from "react";
import {
  carManufacturers,
  serviceOfferedOptions,
  yearsModel,
} from "../../constants";
import { API_URL } from "../../services/url";

type Props = {
  // Prop types for children components
  onNext?: () => void;
};
const VehicleDetails = (Props: Props) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [make, setMake] = useState<string | null>();
  const [model, setModel] = useState("");
  const [year, setYear] = useState<string | null>();
  const [color, setColor] = useState<string>("");
  const [licensePlateNumber, setLicensePlateNumber] = useState("");
  const [registrationDocument, setRegistrationDocument] = useState<File | null>(
    null
  );
  const [insuranceInformation, setInsuranceInformation] = useState<File | null>(
    null
  );
  const [servicesOffered, setServicesOffered] = useState<string | null>();
  // serviceOfferedOptions[0].value as string
  const [loading, setLoading] = useState(false);

  const isFormValid =
    make && model && year && color && licensePlateNumber && servicesOffered;

  const handleSubmit = async () => {
    if (!isFormValid) {
      showNotification({
        title: "Validation Error",
        message: "Please fill in all the required fields.",
        color: "red",
      });
      return;
    }

    setLoading(true);

    try {
      const driverId = localStorage.getItem("userId");
      if (driverId === null || driverId === undefined) {
        window.location.href = "/signin";
        return;
      }

      const formData = new FormData();
      formData.append("make", make);
      formData.append("model", model);
      formData.append("year", year?.toString() || "");
      formData.append("color", color);
      formData.append("license_plate_number", licensePlateNumber);
      // formData.append("registration_document", "");
      // formData.append("insurance_information", "");
      formData.append("services_offered", servicesOffered);
      formData.append("driver", driverId);

      const response = await axios.post(`${API_URL}vehicle/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Handle successful response
      console.log(response.data);
      showNotification({
        title: "Success",
        message: "Vehicle details updated successfully.",
        color: "green",
      });
      // window.location.href = "/successful";
      if (Props?.onNext) Props.onNext();
    } catch (error) {
      // Handle error
      console.error(error);
      showNotification({
        title: "Error",
        message: "An error occurred while updating vehicle details.",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      size={isMobile ? "xs" : "sm"}
      px="lg"
      // py={isMobile ? "md" : "xl"}
      style={{
        // height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "self-start",
        textAlign: "left",
      }}
    >
      <LoadingOverlay visible={loading} />
      <h2>Vehicle Details</h2>
      <Stack style={{ width: "100%" }}>
        <Select
          label="Make"
          placeholder="Select make"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          data={carManufacturers}
          value={make}
          onChange={(value) => setMake(value)}
        />

        <TextInput
          label="Model"
          placeholder="Enter model"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={model}
          onChange={(event) => setModel(event.currentTarget.value)}
        />

        <Select
          label="Year"
          placeholder="Select year"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          data={yearsModel}
          value={year}
          onChange={(value) => setYear(value)}
        />

        {/* <Select
          label="Color"
          placeholder="Select color"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          data={[
            { value: "White", label: "White" },
            // Add more color options
          ]}
          value={color}
          onChange={(value) => setColor(value)}
        /> */}

        <TextInput
          label="Color"
          placeholder="Enter Color"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={color}
          onChange={(event) => setColor(event.currentTarget.value)}
        />

        <TextInput
          label="License Plate Number"
          placeholder="Enter license plate number"
          required
          mb="sm"
          size="md"
          type="text"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={licensePlateNumber}
          onChange={(event) => setLicensePlateNumber(event.currentTarget.value)}
        />

        <Select
          label="Services Offered"
          placeholder="Select services offered"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          data={serviceOfferedOptions}
          value={servicesOffered}
          onChange={(value) => setServicesOffered(value)}
        />

        <Flex justify={"center"}>
          <Button
            w={"90%"}
            size="md"
            variant="gradient"
            gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
            onClick={handleSubmit}
            disabled={!isFormValid || loading}
            loading={loading}
            loaderProps={{ type: "oval" }}
            radius={"md"}
            mt={"sm"}
          >
            Save
          </Button>
        </Flex>
      </Stack>
    </Container>
  );
};

export default VehicleDetails;
