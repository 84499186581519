import { Button, Flex, Modal, Stack, Textarea } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { IconInfoHexagon } from "@tabler/icons-react";
import axios from "axios";
import { memo, useState } from "react";
import { API_URL } from "../../services/url";

type Props = {
  jobId: string | null;
};

export const ReportJob: React.FC<Props> = memo(({ jobId }) => {
  const userId = localStorage.getItem("userId");

  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const [opened, { open, close }] = useDisclosure(false);

  const handleReport = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${API_URL}job-reports/`, {
        job: jobId,
        reason: reason,
        reported_by: userId,
      });

      if (res.data && res.data.id) {
        showNotification({
          title: `Reported`,
          message: `Job reported successfully`,
          color: "green",
        });
        close();
      }
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to report job",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  const reportConfirmationModal = () => {
    modals.openConfirmModal({
      title: `Are you sure want to report this job?`,
      centered: true,
      labels: { confirm: `Yes`, cancel: `No` },
      confirmProps: { color: "primary" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        handleReport();
      },
    });
  };

  return (
    <Flex align={"center"}>
      <IconInfoHexagon stroke={3} onClick={open} />
      <Modal opened={opened} onClose={close} title="Report Job" centered>
        <Stack style={{ width: "100%" }} gap={"lg"}>
          <Textarea
            label="Reason"
            required
            placeholder="Enter Reason"
            size="md"
            onChange={(event) => setReason(event.currentTarget.value)}
            labelProps={{ style: { alignSelf: "flex-start" } }}
          />
          <Button
            fullWidth
            size="md"
            radius={"md"}
            variant="gradient"
            gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
            disabled={reason === "" || loading}
            onClick={reportConfirmationModal}
            loading={loading}
            loaderProps={{ type: "oval" }}
          >
            Report
          </Button>
        </Stack>
      </Modal>
    </Flex>
  );
});
