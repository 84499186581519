import AWS from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';

// BucketName = outss-assets
// Region = me-central-1
// accessKey = AKIAZQ3DS6BCYVESJAPG
// secretKey = 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
AWS.config.update({
  accessKeyId: 'AKIAZQ3DS6BCYVESJAPG',
  secretAccessKey: '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',
  region: 'me-central-1',
});

// Create an S3 instance
const s3 = new AWS.S3();

// Function to upload a file to S3
async function uploadFileToS3(file: File, bucketName: string, key: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      if (!event.target || !event.target.result) {
        reject('Failed to read file');
        return;
      }
      const params: S3.PutObjectRequest = {
        Bucket: bucketName,
        Key: key,
        Body: event.target.result as S3.Body,
        ACL: 'public-read',
        ContentType: file.type,
      };

      try {
        const response = await s3.upload(params).promise();
        resolve(response.Location);
      
      } catch (error) {
        console.error('Error uploading file to S3:', error);
        reject(error);
      }
    };
    reader.readAsArrayBuffer(file);
  });
}

export default uploadFileToS3;

