import { Button, Container, Flex, Modal, Table, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconAlertTriangle, IconCircleCheck, IconCircleX, IconPlayerPlay, IconProgress } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { Trip } from '../CreateJob/type';
import { formattedDateTime } from '../../utils/datetime';

export const TripTable = ({ trip_data }: {trip_data:any}) => {

  const [popupData, setPopupData] = useState<Trip | undefined>()
  
  const [url, SetUrl] = useState('')

  const [opened, { open, close }] = useDisclosure(false);

  // Function to render status icon
  const renderStatusIcon = (status: string) => {
    switch (status.toLowerCase()) {
      case 'started':
        return <IconPlayerPlay color="blue" />;
      case 'completed':
        return <IconCircleCheck color="green" />;
      default:
        return status;
    }
  };

  const handleRedirect = (loading:string) => {
    const [latitude, longitude] = loading.split(",");
    return SetUrl(`https://www.google.com/maps?q=${latitude},${longitude}`);
  };

  const openpopup = (id:string)  => {
    const data = trip_data.find((item:Trip) => item.id === id);
    setPopupData(data)    
    open()
  }
  
  const rows = trip_data?.map((item:Trip) => (
    <Table.Tr key={item.id}>
      <Table.Td>{item.driver_name}</Table.Td>
      <Table.Td>{formattedDateTime(item.start_time)}</Table.Td>
      <Table.Td>{renderStatusIcon(item.status)}</Table.Td>
      <Table.Td>{item.booking_id}</Table.Td>
      <Table.Td>{item.amount_paid}</Table.Td>
      <Table.Td>{item.payment_status ? <IconCircleCheck color='green' /> : <IconCircleX color='red' />}</Table.Td>
      <Table.Td>{formattedDateTime(item.created_at)}</Table.Td>
      <Table.Td><Button color='black' onClick={()=>openpopup(item.id)} >View</Button></Table.Td>
    </Table.Tr>
  ));

  return (
    <>
    <Modal radius={'md'} size={'lg'} opened={opened} title='Trip List' onClose={close} centered>
      {popupData && 
      <Container p={'lg'}>
        <Flex gap={'xl'} justify={'space-between'}>

          <Flex gap={'lg'} direction={'column'} justify={'flex-start'}>
            <Flex direction={'column'}>
              <Text c={'dimmed'}>Start Time</Text>
              <Text size='lg'>{formattedDateTime(popupData.start_time)}</Text>
            </Flex>

            <Flex direction={'column'}>
              <Text c={'dimmed'}>Start Location</Text>
              <Text size='lg'><a href={url} onClick={() => handleRedirect(popupData.start_location)}>View on Map</a></Text>
            </Flex>

            <Flex direction={'column'}>
              <Text c={'dimmed'}>Status</Text>
              <Flex align={'center'} gap={'xs'}>
                {renderStatusIcon(popupData.status)}
                <Text size='lg'>{popupData.status}</Text>
              </Flex>
            </Flex>

            <Flex direction={'column'}>
              <Text c={'dimmed'}>Distance Driven</Text>
              <Text size='lg'>{popupData.distance_driven}</Text>
            </Flex>

            <Flex direction={'column'}>
              <Text c={'dimmed'}>Created At</Text>
              <Text size='lg'>{formattedDateTime(popupData.created_at)}</Text>
            </Flex>
          </Flex>

          <Flex gap={'lg'} direction={'column'} justify={'flex-start'}>
            <Flex direction={'column'}>
              <Text c={'dimmed'}>End Time</Text>
              <Text size='lg'>{formattedDateTime(popupData.end_time)}</Text>
            </Flex>

            <Flex direction={'column'}>
              <Text c={'dimmed'}>End Location</Text>
              <Text size='lg'>{popupData.end_location ? <a href={url} onClick={() => handleRedirect(popupData.end_location)}>View on Map</a> : <Text c={'dimmed'}>Trip Not Completed</Text> }</Text>
            </Flex>

            <Flex direction={'column'}>
              <Text c={'dimmed'}>Payment Status</Text>
                {popupData.payment_status ?
                <Flex align={'center'} gap={'xs'}>
                  {popupData.payment_status === true ? <Flex gap={'xs'}><IconCircleCheck color='green' /><Text>Success</Text></Flex> : <Flex><IconCircleX color='red' /><Text>Pending</Text></Flex>}
                </Flex> : "-"}
            </Flex>

            <Flex direction={'column'}>
              <Text c={'dimmed'}>Amount Paid</Text>
              <Text size='lg'>{popupData.amount_paid}</Text>
            </Flex>

            <Flex direction={'column'}>
              <Text c={'dimmed'}>Invoice</Text>
              <Text size='lg'>{popupData.invoiceurl ? <a href={popupData.invoiceurl}>View Invoice</a> : <Text c={'dimmed'}>Trip Not Completed</Text>}</Text>
            </Flex>
          </Flex> 
 
        </Flex>
      </Container>}
    </Modal>
    <Table highlightOnHover withTableBorder>
      <Table.Thead>
        <Table.Tr>
          <Table.Th ta={'center'}>Name</Table.Th>
          <Table.Th ta={'center'}>Start Time</Table.Th>
          <Table.Th ta={'center'}>Status</Table.Th>
          <Table.Th ta={'center'}>Booking ID</Table.Th>
          <Table.Th ta={'center'}>Amount Paid</Table.Th>
          <Table.Th ta={'center'}>Payment Status</Table.Th>
          <Table.Th ta={'center'}>Created At</Table.Th>
          <Table.Th ta={'center'}>Job</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
    </>
  );
};