const formattedDateTime = (dateStr: string) => {
  const date = new Date(dateStr);

  // Convert to local time
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
  });

  // Adjust the hour to 12 PM
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let minute = minutes.toString().padStart(2, "0"); // add prefix '0' if minutes less than 10
  let hour = hours.toString().padStart(2, "0"); // add prefix '0' if hours less than 10
  const formattedTime = `${hour}:${minute} ${ampm}`;

  return `${formattedDate} | ${formattedTime}`;
};

const formatProfileDateFormat = (dateStr: string) => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  console.log(formattedDate); // Output: 2024-06-13
  return formattedDate
};

const timeDiff = (dateStr: string) => {
  const givenDate = new Date(dateStr);
  const currentDate = new Date();

  const timeDiff = currentDate.getTime() - givenDate.getTime(); // Difference in milliseconds

  const minutes = Math.floor(timeDiff / (1000 * 60));
  const hours = Math.floor(timeDiff / (1000 * 60 * 60));
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  let timeAgo;
  if (days > 0) {
    timeAgo = `${days} days ago`;
  } else if (hours > 0) {
    timeAgo = `${hours} hours ago`;
  } else {
    timeAgo = minutes > 0 ? `${minutes} minutes ago` : `just now`;
  }
  return timeAgo;
};

const humenReadableTime = (dateStr: string | null) => {
  if (!dateStr) return "N/A";
  const date = new Date(dateStr);

  // Convert to local time
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
  });

  // Adjust the hour to 12 PM
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let minute = minutes.toString().padStart(2, "0"); // add prefix '0' if minutes less than 10
  let hour = hours.toString().padStart(2, "0"); // add prefix '0' if hours less than 10
  const formattedTime = `${hour}:${minute} ${ampm}`;

  return `${formattedDate} ${date.getFullYear()} | ${formattedTime}`;
};

export {
  formattedDateTime,
  timeDiff,
  humenReadableTime,
  formatProfileDateFormat,
};
