import React, { useEffect, useState } from 'react';
import { Button, Container, Flex, LoadingOverlay, Stack, Tabs, Text, Title, Table } from '@mantine/core';
import { IconArrowLeft, IconBriefcase, IconDownload, IconHourglassLow, IconRulerMeasure2 } from '@tabler/icons-react';
import { DateInput } from '@mantine/dates';
import { Transition } from '@mantine/core';
import axios from 'axios';
import { showNotification } from '@mantine/notifications';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { API_URL } from '../../services/url';
import { Driver_ReportProps } from '../CreateJob/type';
import checkAdmin from '../AdminLogin/checkAdmin';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export const DriverReport = () => {
  const [data, setData] = useState<Driver_ReportProps>();
  const [loading, setLoading] = useState(false);
  const [customTabVisible, setCustomTabVisible] = useState(false);
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const location = useLocation();
  const { driverId } = location.state || {};

  const allow = fromDate && toDate;

  const formatDate = (date: Date | null): string | null => {
    if (!date) return null;
    return date.toISOString().split('T')[0];
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    let yPos = 15;
    
    // Add title
    doc.setFontSize(18);
    doc.text('Driver Report', 105, yPos, { align: 'center' });
    yPos += 20;
    
    // Add driver info with different font sizes for variable names and values
    const addDriverInfoLine = (label: string, value: string | number | undefined) => {
      doc.setFontSize(10);
      doc.text(`${label}:`, 20, yPos);
      doc.setFontSize(12);
      doc.text(value !== undefined ? value.toString() : '-', 45, yPos);
      yPos += 10;
    };

    addDriverInfoLine('Name', data?.driver_info.name);
    addDriverInfoLine('Email', data?.driver_info.email);
    addDriverInfoLine('Phone', data?.driver_info.phone);
    addDriverInfoLine('License Plate', data?.driver_info.vehicle_info?.license_plate);

    yPos += 10; // Add some extra space after driver info

    // Add earnings info
    doc.setFontSize(14);
    doc.text('Earnings', 105, yPos, { align: 'center' });
    yPos += 10;
    
    const earningsData = [
      ['Total Earnings', `$${data?.weekly_report.total_earnings || '0'}`],
      ['Total Jobs', data?.weekly_report.total_jobs?.toString() || '0'],
      ['Average Duration', duration(data?.weekly_report.average_duration) || '-'],
      ['Total Distance', data?.weekly_report.total_distance?.toString() || '0']
    ];
    
    autoTable(doc, {
      startY: yPos,
      head: [['Metric', 'Value']],
      body: earningsData,
    });

    // Update yPos after the table
    yPos = (doc as any).lastAutoTable.finalY + 15;

    // Add job details
    doc.setFontSize(14);
    doc.text('Job Details', 105, yPos, { align: 'center' });
    yPos += 10;
    
    const jobData = data?.weekly_report.job_details.map(item => [
      item.booking_id || '-',
      item.pickup || '-',
      item.dropoff || '-',
      item.amount?.toString() || '-',
      item.date || '-',
      item.services || '-'
    ]) || [];
    
    autoTable(doc, {
      startY: yPos,
      head: [['Job ID', 'Pickup', 'Dropoff', 'Amount', 'Date', 'Service']],
      body: jobData,
    });

    // Save the PDF
    doc.save(`${data?.driver_info.name} Report.pdf`);
  };

  useEffect(() => {
    checkAdmin();
  }, []);
  
  const fetch_Report = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}driverreport/?driver_id=${driverId}&start_date=&end_date=`);
      setData(response.data);
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to fetch Report",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  }

  const fetch_customReport = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}driverreport/?driver_id=${driverId}&start_date=${fromDate}&end_date=${toDate}`);
      setData(response.data);
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to fetch Custom Report",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetch_Report();
  }, []);

  const weeklyRows = data?.weekly_report.job_details.map((item) => (
    <Table.Tr key={item.booking_id}>
      <Table.Td ta={'center'}>{item.booking_id}</Table.Td>
      <Table.Td ta={'center'}>{item.pickup}</Table.Td>
      <Table.Td ta={'center'}>{item.dropoff}</Table.Td>
      <Table.Td ta={'center'}>{item.amount}</Table.Td>
      <Table.Td ta={'center'}>{item.date}</Table.Td>
      <Table.Td ta={'center'}>{item.services}</Table.Td>
    </Table.Tr>
  ));

  const monthlyRows = data?.monthly_report.job_details.map((item) => (
    <Table.Tr key={item.booking_id}>
      <Table.Td ta={'center'}>{item.booking_id}</Table.Td>
      <Table.Td ta={'center'}>{item.pickup}</Table.Td>
      <Table.Td ta={'center'}>{item.dropoff}</Table.Td>
      <Table.Td ta={'center'}>{item.amount}</Table.Td>
      <Table.Td ta={'center'}>{item.date}</Table.Td>
      <Table.Td ta={'center'}>{item.services}</Table.Td>
    </Table.Tr>
  ));
  
  const customRows = data?.custom_range_report?.job_details.map((item) => (
    <Table.Tr key={item.booking_id}>
      <Table.Td ta={'center'}>{item.booking_id}</Table.Td>
      <Table.Td ta={'center'}>{item.pickup}</Table.Td>
      <Table.Td ta={'center'}>{item.dropoff}</Table.Td>
      <Table.Td ta={'center'}>{item.amount}</Table.Td>
      <Table.Td ta={'center'}>{item.date}</Table.Td>
      <Table.Td ta={'center'}>{item.services}</Table.Td>
    </Table.Tr>
  ));
  
  
  const duration = (time: string | undefined) => {
    if (time === undefined) return '';
    const spliter = time?.toString().split(':');
    const formatedTime = `${spliter?.[0]}:${spliter?.[1]}:${spliter?.[2].split('.')[0]}`;
    return formatedTime;
  }

  function HeaderCom() {      
    return (
      <Flex justify={"space-between"} align={"center"} w={"100%"}>
        <IconArrowLeft
          style={{ width: 30, height: 30 }}
          onClick={() => window.history.back()}
        />
        <Title order={2} ta={"center"} w={"100%"} fw={700}>
          Report
        </Title>
      </Flex>
    );
  }

  return (
    <Container
      size={isMobile ? "xs" : "sm"}
      px="lg"
      py={isMobile ? "md" : "xl"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "self-start",
        textAlign: "left",
      }}
      bg={"bg"}
    >
      <HeaderCom />
      <LoadingOverlay visible={loading} />

      <Stack
        style={{
          width: "100%",
          border: "1px solid #091f5b",
          borderRadius: "10px",
        }}
        p="lg"
        gap={"lg"}
        mt={"lg"}
      >
        <Flex justify={'space-between'} p={'lg'}>
          <Flex direction={'column'} gap={'xl'}>
            <Flex direction={'column'}>
              <Text c={'dimmed'}>Name</Text>
              <Text size='lg' fw={'600'}>{data?.driver_info.name}</Text>
            </Flex>
            <Flex direction={'column'}>
              <Text c={'dimmed'}>Mail ID</Text>
              <Text size='lg' fw={'600'}>{data?.driver_info.email}</Text>
            </Flex>
            <Flex direction={'column'}>
              <Text c={'dimmed'}>Mobile No</Text>
              <Text size='lg' fw={'600'}>{data?.driver_info.phone}</Text>
            </Flex>
          </Flex>
          <Flex direction={'column'} gap={'xl'}>
            <Flex direction={'column'}>
              <Text c={'dimmed'}>License Plate Number</Text>
              <Text size='lg' fw={'600'}>{data?.driver_info.vehicle_info? data.driver_info.vehicle_info.license_plate : '-'}</Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex w={"100%"} mt={"md"} justify={'center'}>
          <Tabs 
            w={'100%'} 
            color='black' 
            defaultValue="weekly_report" 
            variant="pills"
            onChange={(value) => {
              setCustomTabVisible(value === 'custome');
            }}
          >
            <Tabs.List justify='center'>
              <Tabs.Tab w={'30%'} size={"md"} value="weekly_report" bd={'1px solid black'}>
                Weekly Report
              </Tabs.Tab>
              <Tabs.Tab w={'30%'} size={"md"} value="monthly_report" bd={'1px solid black'}>
                Monthly Report
              </Tabs.Tab>
              <Tabs.Tab w={'30%'} size={"md"} value="custome" bd={'1px solid black'}>
                Custom Report
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="weekly_report" pt="md" style={{overflow:"auto"}}>
              <Stack
                style={{
                  width: "100%",
                  border: "1px solid #091f5b",
                  borderRadius: "10px",
                }}
                p="lg"
                gap={"lg"}
              >
                <Title order={3} ta={'center'}>Earnings</Title>
                <Flex justify={'space-around'}>
                  <Flex direction={'column'} gap={'lg'}>
                  <Flex direction={'column'} align={'center'}>
                      <Text c={'dimmed'}>Total Earnings</Text>
                      <Flex align={'center'} gap={'xs'}>
                        <Text c='gray' size='20px'>$</Text>
                        <Text size='xl' fw={'600'}>{data?.weekly_report.total_earnings}</Text>
                      </Flex>
                    </Flex>
                    <Flex direction={'column'} align={'center'}>
                      <Text c={'dimmed'}>Total Jobs</Text>
                      <Flex align={'center'} gap={'xs'}>
                        <IconBriefcase color='gray' size={25} />
                        <Text size='xl' fw={'600'}>{data?.weekly_report.total_jobs}</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex direction={'column'} gap={'lg'}>
                    <Flex direction={'column'} align={'center'}>
                      <Text c={'dimmed'}>Average Duration</Text>
                      <Flex align={'center'} gap={'xs'}>
                        <IconHourglassLow color='gray' size={25} />
                        <Text size='xl' fw={'600'}>{duration(data?.weekly_report.average_duration)}</Text>
                      </Flex>
                    </Flex>
                    <Flex direction={'column'} align={'center'}>
                      <Text c={'dimmed'}>Total Distance</Text>
                      <Flex align={'center'} gap={'xs'}>
                        <IconRulerMeasure2 color='gray' size={25} />
                        <Text size='xl' fw={'600'}>{data?.weekly_report.total_distance}</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Stack>
              <Flex direction={'column'} gap={'md'} mt={'sm'} style={{overflow:'auto'}}>
                <Title order={3} ta={'center'}>Weekly Job Details</Title>
                <Table striped={'odd'} highlightOnHover>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th ta={'center'}>Job ID</Table.Th>
                      <Table.Th ta={'center'}>Pickup</Table.Th>
                      <Table.Th ta={'center'}>Dropoff</Table.Th>
                      <Table.Th ta={'center'}>Amount</Table.Th>
                      <Table.Th ta={'center'}>Date</Table.Th>
                      <Table.Th ta={'center'}>Service</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {weeklyRows}
                  </Table.Tbody>
                </Table>
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value="monthly_report" pt="md" style={{overflow:"auto"}}>
              <Stack
                style={{
                  width: "100%",
                  border: "1px solid #091f5b",
                  borderRadius: "10px",
                }}
                p="lg"
                gap={"lg"}
              >
                 <Title order={3} ta={'center'}>Earnings</Title>
                <Flex justify={'space-around'}>
                  <Flex direction={'column'} gap={'lg'}>
                  <Flex direction={'column'} align={'center'}>
                      <Text c={'dimmed'}>Total Earnings</Text>
                      <Flex align={'center'} gap={'xs'}>
                        <Text c='gray' size='20px'>$</Text>
                        <Text size='xl' fw={'600'}>{data?.monthly_report.total_earnings}</Text>
                      </Flex>
                    </Flex>
                    <Flex direction={'column'} align={'center'}>
                      <Text c={'dimmed'}>Total Jobs</Text>
                      <Flex align={'center'} gap={'xs'}>
                        <IconBriefcase color='gray' size={25} />
                        <Text size='xl' fw={'600'}>{data?.monthly_report.total_jobs}</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex direction={'column'} gap={'lg'}>
                    <Flex direction={'column'} align={'center'}>
                      <Text c={'dimmed'}>Average Duration</Text>
                      <Flex align={'center'} gap={'xs'}>
                        <IconHourglassLow color='gray' size={25} />
                        <Text size='xl' fw={'600'}>{duration(data?.monthly_report.average_duration)}</Text>
                      </Flex>
                    </Flex>
                    <Flex direction={'column'} align={'center'}>
                      <Text c={'dimmed'}>Total Distance</Text>
                      <Flex align={'center'} gap={'xs'}>
                        <IconRulerMeasure2 color='gray' size={25} />
                        <Text size='xl' fw={'600'}>{data?.monthly_report.total_distance}</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Stack>
              <Flex direction={'column'} gap={'md'} mt={'sm'} style={{overflow:'auto'}}>
                <Title order={3} ta={'center'}>Monthly Job Details</Title>
                <Table striped={'odd'} highlightOnHover>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th ta={'center'}>Job ID</Table.Th>
                      <Table.Th ta={'center'}>Pickup</Table.Th>
                      <Table.Th ta={'center'}>Dropoff</Table.Th>
                      <Table.Th ta={'center'}>Amount</Table.Th>
                      <Table.Th ta={'center'}>Date</Table.Th>
                      <Table.Th ta={'center'}>Service</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {monthlyRows}
                  </Table.Tbody>
                </Table>
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value="custome" pt="md" style={{overflow:"auto"}}>
              <Transition mounted={customTabVisible} transition="fade" duration={200} timingFunction="ease">
                {(styles) => (
                  <Flex direction="column" style={styles}>
                    <Flex gap={'lg'} justify={'center'}>
                      <DateInput
                        w={'45%'}
                        valueFormat="DD-MM-YYYY"
                        label="From"
                        placeholder="From Date"
                        onChange={(date) => setFromDate(formatDate(date))}
                      />
                      <DateInput
                        w={'45%'}
                        valueFormat="DD-MM-YYYY"
                        label="To"
                        placeholder="To Date"
                        onChange={(date) => setToDate(formatDate(date))}
                      />
                    </Flex> 
                    <Flex justify={'center'} my={'lg'}>
                      <Button disabled={!allow} color={'black'} w={'50%'} onClick={fetch_customReport}>Submit</Button>
                    </Flex>
                  </Flex>
                )}
              </Transition>
              {data?.custom_range_report && <>
              <Stack
                style={{
                  width: "100%",
                  border: "1px solid #091f5b",
                  borderRadius: "10px",
                }}
                p="lg"
                gap={"lg"}
              >
                 <Title order={3} ta={'center'}>Earnings</Title>
                <Flex justify={'space-around'}>
                  <Flex direction={'column'} gap={'lg'}>
                    <Flex direction={'column'} align={'center'}>
                      <Text c={'dimmed'}>Total Earnings</Text>
                      <Flex align={'center'} gap={'xs'}>
                        <Text c='gray' size='20px'>$</Text>
                        <Text size='xl' fw={'600'}>{data?.custom_range_report.total_earnings}</Text>
                      </Flex>
                    </Flex>
                    <Flex direction={'column'} align={'center'}>
                      <Text c={'dimmed'}>Total Jobs</Text>
                      <Flex align={'center'} gap={'xs'}>
                        <IconBriefcase color='gray' size={25} />
                        <Text size='xl' fw={'600'}>{data?.custom_range_report.total_jobs}</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex direction={'column'} gap={'lg'}>
                    <Flex direction={'column'} align={'center'}>
                      <Text c={'dimmed'}>Average Duration</Text>
                      <Flex align={'center'} gap={'xs'}>
                        <IconHourglassLow color='gray' size={25} />
                        <Text size='xl' fw={'600'}>{duration(data?.custom_range_report.average_duration)}</Text>
                      </Flex>
                    </Flex>
                    <Flex direction={'column'} align={'center'}>
                      <Text c={'dimmed'}>Total Distance</Text>
                      <Flex align={'center'} gap={'xs'}>
                        <IconRulerMeasure2 color='gray' size={25} />
                        <Text size='xl' fw={'600'}>{data?.custom_range_report.total_distance}</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Stack>
              <Flex direction={'column'} gap={'md'} mt={'sm'} style={{overflow:'auto'}}>
                <Title order={3} ta={'center'}>Custom Job Details</Title>
                <Table striped={'odd'} highlightOnHover>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th ta={'center'}>Job ID</Table.Th>
                      <Table.Th ta={'center'}>Pickup</Table.Th>
                      <Table.Th ta={'center'}>Dropoff</Table.Th>
                      <Table.Th ta={'center'}>Amount</Table.Th>
                      <Table.Th ta={'center'}>Date</Table.Th>
                      <Table.Th ta={'center'}>Service</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {customRows}
                  </Table.Tbody>
                </Table>
              </Flex></>}

            </Tabs.Panel>
          </Tabs>
        </Flex>
      </Stack>
      <Flex w={'100%'} justify="center" mt="xl">
        <Button
          leftSection={<IconDownload size={20} />}
          onClick={generatePDF}
          color="black"
        >
          Download Report
        </Button>
      </Flex>
    </Container>
  );
};