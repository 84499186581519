import { MantineProvider, colorsTuple, createTheme } from "@mantine/core";
import "@mantine/core/styles.css";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { useEffect } from "react";
import "./App.css";
import RoutesApp from "./routes";

const theme = createTheme({
  /** Put your mantine theme override here */
  fontFamily: "Nunito Sans",
  colors: {
    // primary: colorsTuple("#091f5b"),
    primary: colorsTuple("#2B2B2B"),
    secondary: colorsTuple("#edf0f5"),
    hcolor: colorsTuple("#808080"),    
    fcolor: colorsTuple("#F5F5F5"),
    bg: colorsTuple("#FAFAFA"),
  },
});

function App() {
  // utils/detectDevice.js

  // const isMobile = () => {
  //   const userAgent = navigator.userAgent || navigator.vendor;

  //   // Check for mobile user agents
  //   const isMobileUserAgent = /Mobi|Android/i.test(userAgent);

  //   // Check for touch events
  //   const isTouchDevice =
  //     "ontouchstart" in window || navigator.maxTouchPoints > 0;

  //   return isMobileUserAgent && isTouchDevice;
  // };

  useEffect(() => {
    // Add a custom font to the head
    if (process.env.NODE_ENV !== "production") {
      // localStorage.setItem('userId', '1b9f01e2-32f6-45a0-bff4-75961dfb7483')
      // localStorage.setItem('userId',"f847f7d4-5ba4-4fe0-9ae5-374761599989")
      // if(/Mobi|Android/i.test(navigator.userAgent)){
      //   localStorage.setItem('userId', 'c6a8e5a9-0fa8-4b81-9c3b-96b56d70f1d2')
      // }
      // if(isMobile())
      //   localStorage.setItem('userId', 'e7579371-890a-4584-b215-51af3c2460f6')
    }
  }, []);

  return (
    <div className="App">
      <MantineProvider theme={theme}>
        <ModalsProvider>
          <Notifications />
          <RoutesApp />
        </ModalsProvider>
      </MantineProvider>
    </div>
  );
}

export default App;
