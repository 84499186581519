import {
  Button,
  Container,
  Flex,
  Image,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import axios from "axios";
import { useLayoutEffect, useState } from "react";
import { URL } from "../../services/url";
import checkUserId from "../../utils/checkuserid";

interface SignInResponse {
  success: boolean;
  response: string;
  user?: number;
}

const SignIn = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const isFormValid = email && password && !passwordErr && !loading && !error;

  useLayoutEffect(() => {
    checkUserId();

    // window.localStorage.setItem("userId", "4");
  }, []);

  const handleSignUp = async (e: any) => {
    e.preventDefault();
    if (isFormValid)
      try {
        setError("");
        setLoading(true);
        const response = await axios.post<SignInResponse>(`${URL}signin/`, {
          email,
          password,
        });

        console.log("🚀 ~ handleSignUp ~ response:", response);

        if (response.data.success) {
          const userId = response.data.user;
          // Store the user ID or any other relevant data in local storage or state management solution
          localStorage.setItem("userId", userId?.toString() || "");

          // const res = await axios.get(`${URL}check/${userId}/`);
          window.location.href = "/home";
          // else window.location.href = "/personal";
        } else {
          setError(response.data.response);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Sign up failed", error);
        setError("An error occurred during sign up");
      }
  };

  const passwordPattern =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const onBluePassword = (e: any) => {
    if (!passwordPattern.test(password)) {
      setError(
        "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character"
      );
      setPasswordErr(true);
    } else {
      setError("");
      setPasswordErr(false);
    }
  };

  return (
    <Container
      size={isMobile ? "xs" : "sm"}
      px="lg"
      py={isMobile ? "md" : "xl"}
      style={{
        height: "95vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "left",
      }}
      bg={"bg"}
    >
      <Flex direction={"column"} align={"center"} gap={"xs"}>
        <Image src={"landingimg.png"} />
        <Title order={1} style={{ fontSize: isMobile ? "2rem" : "4rem" }}>
          Kabbie
        </Title>
        <Text size="sm">Ride as one community.</Text>
      </Flex>
      <Flex
        direction="row"
        align="center"
        justify="center"
        style={{ width: "100%" }}
        mt={"lg"}
      >
        <Stack style={{ width: "100%" }}>
        <Title order={2} style={{ fontSize: "1.5rem" }}>
            Driver Signin
          </Title>
          <TextInput
            label="Email"
            placeholder="Enter your email"
            type="email"
            size="md"
            required
            labelProps={{ style: { alignSelf: "flex-start" } }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onFocusCapture={(e) => setError("")}
          />

          <PasswordInput
            label="Password"
            placeholder="Enter your password"
            required
            mb="xs"
            size="md"
            labelProps={{ style: { alignSelf: "flex-start" } }}
            value={password}
            error={passwordErr}
            onChange={(e) => setPassword(e.target.value)}
            onFocusCapture={(e) => setError("")}
            onBlurCapture={onBluePassword}
          />

          {error && (
            <Text color="red" size="sm" mb="md">
              {error}
            </Text>
          )}

          <Flex justify={"center"}>
            <Button
              w={"90%"}
              size="md"
              mt="sm"
              variant="gradient"
              gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
              c={"fcolor"}
              onClick={handleSignUp}
              radius={"md"}
              loading={loading}
              loaderProps={{ type: "oval" }}
            >
              Signin
            </Button>
          </Flex>

          <Text size="sm" ta={"center"}>
            Don't have an account?{" "}
            <Text component="a" href="/signup" inherit>
              Signup
            </Text>
          </Text>
        </Stack>
      </Flex>
    </Container>
  );
};

export default SignIn;
