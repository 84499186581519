import { Button, Flex, Loader, Paper, Text, Title } from "@mantine/core";
import { modals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { memo, useEffect, useState } from "react";
import { API_URL } from "../../services/url";
import { InvitationProps, JobProps, RequestJobProps } from "../CreateJob/type";

type Props = {
  jobId: string | null;
  jobInfo: JobProps;
  onRefetch: () => void;
};

export const AcceptOrDeclineJob: React.FC<Props> = memo(
  ({ jobId, jobInfo, onRefetch }) => {
    const userId = localStorage.getItem("userId");

    const [loading, setLoading] = useState(false);
    const [invitation, setinvitation] = useState<InvitationProps | null>(null);
    const [requestedJob, setRequestedJob] = useState<RequestJobProps[] | null>(
      null
    );
    const [requestCount, setRequestCount] = useState(0);
    
    const fetchInvitation = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${API_URL}invites-list/?job=${jobId}`
        );
        if (response.data.length > 0) {
          // iterate over all data and see if user id matches with invited_driver
          // if yes then set invitation
          response.data.map(
            (item: InvitationProps) => {              
              if ( item.invited_driver == userId)
              {
                console.log(item);
                setinvitation(item);
                return true;
              }
            }
          )
        
        }
      
      } catch (error) {
        showNotification({
          title: "Error",
          message: "Failed to fetch request jobs",
          color: "red",
        });
      } finally {
        setLoading(false);
      }
    };

    const fetchRequestedList = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_URL}job-request/?job=${jobId}`);
        if (response.data.length > 0) {
          // if(!response.data.some(
          //   (item: RequestJobProps) => item.status === "accepted"
          // ))

          setRequestedJob(response.data);

          setRequestCount(
            response.data.reduce((acc: number, item: RequestJobProps) => {
              if (item.status === "pending") {
                return acc + 1;
              }
              return acc;
            }, 0)
          );
        }
      } catch (error) {
        showNotification({
          title: "Error",
          message: "Failed to fetch request jobs",
          color: "red",
        });
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      fetchInvitation();
      fetchRequestedList();
    }, []);

    const handleInvitation = async (isAccepted: boolean) => {
      try {
        let response;
        if (isAccepted)
          response = await axios.post(
            `${API_URL}job-invite/${invitation?.id}/accept/`
          );
        else
          response = await axios.post(
            `${API_URL}job-invite/${invitation?.id}/reject/`
          );
        console.log("🚀 ~ handleInvitation ~ response:", response);
        if (response.data && response.data.message) {
          showNotification({
            title: `${isAccepted ? "Accepted" : "Rejected"}`,
            message: `Invitation ${isAccepted ? "Accepted" : "Rejected"}`,
            color: "green",
          });
          fetchInvitation();
          onRefetch();
        }
      } catch (error) {
        showNotification({
          title: "Error",
          message: "Failed to accept/reject invitation",
          color: "red",
        });
      }
    };

    const handleRequestJob = async () => {
      try {
        setLoading(true);
        const res = await axios.post(`${API_URL}job-request/`, {
          status: "pending",
          job: jobInfo?.id,
          requested_by_driver: userId,
        });
        if (res.data && res.data.id) {
          fetchRequestedList();
          showNotification({
            title: "Success",
            message: "Job requested successfully",
            color: "green",
          });
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    const alertModal = (keyName: string) => {
      modals.openConfirmModal({
        title: `Are you sure want to ${keyName} invitation?`,
        centered: true,
        children: (
          <Text size="sm">{`Are you sure want to ${keyName} invitation?`}</Text>
        ),
        labels: { confirm: `${keyName}`, cancel: `No, not ${keyName}` },
        confirmProps: { color: "primary" },
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          handleInvitation(keyName === "accept");
        },
      });
    };

    const requestJobAlert = () => {
      modals.openConfirmModal({
        title: "Request Job",
        centered: true,
        children: (
          <Text size="sm">{"Are you sure want to request this job?"}</Text>
        ),
        labels: { confirm: "Request Job", cancel: "No don't request it" },
        confirmProps: { color: "primary" },
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          handleRequestJob();
        },
      });
    };

    const isRequested =
      requestedJob &&
      requestedJob?.find(
        (item: RequestJobProps) => item.requested_by_driver.id === userId
      );

    const canRequestJob = requestedJob?.some(
      (item: RequestJobProps) => item.status === "accepted"
    );

    if (jobInfo.posted_by.id === userId) return null;

    if (loading) return <Loader color="blue" />;

    if (
      isRequested &&
      jobInfo.assigned_to !== isRequested.requested_by_driver.id &&
      !canRequestJob
    )
      return (
        <Flex direction={"column"} my={"md"}>
          <Title
            order={3}
            ta={"center"}
          >{`Job Request ${isRequested.status}`}</Title>
          {isRequested?.status === "pending" && (
            <Text
              size={"xs"}
              mt={"xs"}
              ta={"center"}
            >{`${requestCount} requested for this job`}</Text>
          )}
        </Flex>
      );

    const isRequestLimitReached = requestedJob
      ? requestedJob.length !== 3
      : true;

    if (
      !invitation &&
      !isRequested &&
      !canRequestJob &&
      jobInfo.assigned_to !== userId &&
      isRequestLimitReached
    )
      return (
        <Paper shadow="xs" p="lg" bg={"secondary"} m={"lg"}>
          {jobInfo.amount > 0 && (
            <Text size={"xs"} ta={"center"}>{`Take Budget`}</Text>
          )}
          {jobInfo.amount > 0 && (
            <Title order={1} ta={"center"}>{`$${jobInfo.amount}`}</Title>
          )}
          <Button
            fullWidth
            radius={"md"}
            size="md"
            variant="gradient"
            gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
            mt={"lg"}
            disabled={loading}
            onClick={requestJobAlert}
            loading={loading}
            style={{ height: "50px" }}
            loaderProps={{ type: "oval" }}
          >
            Request Job
          </Button>
        </Paper>
      );

    if (invitation?.status === "accepted"  ) return null;
    // return <Title order={3} mb="md" ta={"center"}>{`Invitaion Accepted`}</Title>;

    if (invitation?.status === "declined")
      return (
        <Title order={3} mb="md" ta={"center"}>{`Invitaion Rejected`}</Title>
      );

    if (invitation)
      return (
        <Flex justify={"center"} align={"center"} w={"100%"} gap={"md"}>
          <Button
            fullWidth
            size="md"
            color="red"
            variant="filled"
            mt={"lg"}
            disabled={loading}
            onClick={() => alertModal("reject")}
            loading={loading}
            style={{ height: "50px" }}
            loaderProps={{ type: "oval" }}
          >
            {`Decline Job`}
          </Button>
          <Button
            fullWidth
            size="md"
            color="green"
            variant="filled"
            mt={"lg"}
            disabled={loading}
            onClick={() => alertModal("accept")}
            loading={loading}
            style={{ height: "50px" }}
            loaderProps={{ type: "oval" }}
          >
            {`Accept Job`}
          </Button>
        </Flex>
      );

    return null;
  }
);
