import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Authentication from "./pages/Authentication";
import CreateJob from "./pages/CreateJob";
import Home from "./pages/Home";
import JobInfo from "./pages/JobInfo";
import JobScheduledDetails from "./pages/JobScheduledDetails";
import LandingPage from "./pages/LandingPage";
import Notifications from "./pages/Notifications";
import OnBoarding from "./pages/OnBoarding";
import { Profile } from "./pages/Profile";
import SignIn from "./pages/Signin";
import SuccessfulRegistration from "./pages/SuccessfulRegistration";
import { Dashboard } from "./pages/Dashboard";
import { DriverReport } from "./pages/Dashboard/report";
import { AdminLogin } from "./pages/AdminLogin";

const RoutesApp = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<Authentication />} />
        <Route path="/successful" element={<SuccessfulRegistration />} />

        <Route path="/personal" element={<OnBoarding />} />

        <Route path="/home" element={<Home />} />

        <Route path="/createjob" element={<CreateJob />} />
        <Route path="/job" element={<JobInfo />} />
        <Route path="/jobschedule" element={<JobScheduledDetails />} />

        <Route path="/profile" element={<Profile />} />
        <Route path="/notifications" element={<Notifications />} />
        {/* <Route path="/demo" element={<Demo />} /> */}
        <Route path="/dashboard" element={<Dashboard/>} />

        <Route path="/report" element={<DriverReport/>} />

        <Route path="admin/login" element={<AdminLogin/>} />
      </Routes>
    </Router>
  );
};

export default RoutesApp;
