import React, { useState, useEffect } from 'react';
import { Select, Text, Grid, Paper } from '@mantine/core';

interface CustomTimePickerProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  required?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const CustomTimePicker: React.FC<CustomTimePickerProps> = ({
  value,
  onChange,
  label = 'Pick time',
  required = false,
  size = 'sm'
}) => {
  const [hours, setHours] = useState(value.split(/:|\s/)[0]);
  const [minutes, setMinutes] = useState(value.split(/:|\s/)[1]);

  useEffect(() => {
      const newTime = `${hours}:${minutes}`;
      onChange(newTime);
  }, [hours, minutes, onChange]);

  const handleChange = (field: 'hours' | 'minutes', newValue: string | null) => {
    if (newValue) {
      if (field === 'hours') {
        setHours(newValue);
      } else {
        setMinutes(newValue);
      }
    }
  };

  return (
    <Paper style={{ width: '100%' }}>
      {label && (
        <Text size={size} fw={500} mb="sm">
          {label}{required && <span style={{ color: 'red' }}> *</span>}
        </Text>
      )}
      <Grid gutter="xs">
        <Grid.Col span={6}>
          <Select
            data={Array.from({ length: 24 }, (_, i) => ({ value: String(i + 1).padStart(2, '0'), label: String(i + 1).padStart(2, '0') }))}
            value={hours}
            onChange={(value) => handleChange('hours', value)}
            size={size}
            style={{ width: '100%' }}
            placeholder='Hours'
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            data={Array.from({ length: 60 }, (_, i) => ({ value: String(i).padStart(2, '0'), label: String(i).padStart(2, '0') }))}
            value={minutes}
            onChange={(value) => handleChange('minutes', value)}
            size={size}
            style={{ width: '100%' }}
            placeholder='Minutes'
          />
        </Grid.Col>
      </Grid>
    </Paper>
  );
};

export default CustomTimePicker;