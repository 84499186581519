const AdminPathList = [
    "/dashboard",
    "/report",
  ];
  
  const AuthPathList = ["/admin/login"];
  
  const checkAdmin = () => {
    const driverId = localStorage.getItem("adminId");
  
    if (driverId === null || driverId === undefined) {
      if (AuthPathList.includes(window.location.pathname)) return;
      window.location.href = "/admin/login";
      return;
    } else {
      if (AdminPathList.includes(window.location.pathname)) return;
      window.location.href = "/dashboard";
    }
  };
  
  export default checkAdmin;