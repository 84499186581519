const SuburbsList = [
  { label: "Bondi", value: "Bondi" },
  { label: "Darlinghurst", value: "Darlinghurst" },
  { label: "Surry Hills", value: "Surry Hills" },
  { label: "North Sydney", value: "North Sydney" },
  { label: "Mosman", value: "Mosman" },
  { label: "Manly", value: "Manly" },
  { label: "Parramatta", value: "Parramatta" },
  { label: "Blacktown", value: "Blacktown" },
  { label: "Penrith", value: "Penrith" },
  { label: "Carlton", value: "Carlton" },
  { label: "Fitzroy", value: "Fitzroy" },
  { label: "Southbank", value: "Southbank" },
  { label: "Brunswick", value: "Brunswick" },
  { label: "Coburg", value: "Coburg" },
  { label: "Preston", value: "Preston" },
  { label: "Box Hill", value: "Box Hill" },
  { label: "Doncaster", value: "Doncaster" },
  { label: "Ringwood", value: "Ringwood" },
  { label: "St Kilda", value: "St Kilda" },
  { label: "Brighton", value: "Brighton" },
  { label: "Caulfield", value: "Caulfield" },
  { label: "New Farm", value: "New Farm" },
  { label: "Kangaroo Point", value: "Kangaroo Point" },
  { label: "Fortitude Valley", value: "Fortitude Valley" },
  { label: "Chermside", value: "Chermside" },
  { label: "Aspley", value: "Aspley" },
  { label: "Northgate", value: "Northgate" },
  { label: "Sunnybank", value: "Sunnybank" },
  { label: "Mount Gravatt", value: "Mount Gravatt" },
  { label: "Logan", value: "Logan" },
  { label: "Subiaco", value: "Subiaco" },
  { label: "Leederville", value: "Leederville" },
  { label: "Northbridge", value: "Northbridge" },
  { label: "Joondalup", value: "Joondalup" },
  { label: "Wanneroo", value: "Wanneroo" },
  { label: "Clarkson", value: "Clarkson" },
  { label: "Fremantle", value: "Fremantle" },
  { label: "Rockingham", value: "Rockingham" },
  { label: "Mandurah", value: "Mandurah" },
  { label: "North Adelaide", value: "North Adelaide" },
  { label: "Unley", value: "Unley" },
  { label: "Norwood", value: "Norwood" },
  { label: "Salisbury", value: "Salisbury" },
  { label: "Elizabeth", value: "Elizabeth" },
  { label: "Gawler", value: "Gawler" },
  { label: "Glenelg", value: "Glenelg" },
  { label: "Morphett Vale", value: "Morphett Vale" },
  { label: "Noarlunga", value: "Noarlunga" },
];

const CarSeaterList = [
  { group: "Sedan upto 4 persons", items: ["1 wheelchair", "2 wheelchair"] },
  { group: "SUV", items: ["SUV"] },
  { group: "Wheelchair", items: ["wheelchair"] },
  { group: "Luxury upto 4 persons", items: ["Luxury upto 4 persons"] },
  {
    group: "Maxi",
    items: [
      "5 persons",
      "6 persons",
      "7 persons",
      "8 persons",
      "9 persons",
      "10 persons",
      "11 persons",
    ],
  },
];

const SortByList = [
  { label: "Recent jobs", value: "pickup_at#asc" },
  { label: "Upcoming jobs", value: "pickup_at#desc" },
  { label: "Recent posts", value: "posted_at#desc" },
  { label: "Oldest posts", value: "posted_at#asc" },
  { label: "Price Low", value: "price#asc" },
  { label: "Price High", value: "price#desc" },
  { label: "Distance Low", value: "distance#asc" },
  { label: "Distance High", value: "distance#desc" },
];

const ReportFilterList = [
  { label: "Started", value: "started" },
  { label: "Completed", value: "completed" },
];

const ReportSortList= [
  { label: "A - Z", value: "start_time" },
  { label: "Z - A", value: "-end_time" },
];

const JobFilterList = [
  { label: "Pending", value: "pending" },
  { label: "Assigned", value: "assigned" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Duplicate", value: "duplicate" },
  { label: "Completed", value: "completed" },
  { label: "Uncovered", value: "uncovered" },
];

const JobSortByList = [
  { label: "Recent jobs", value: "-pickup_at" },
  { label: "Oldest jobs", value: "pickup_at" },
  { label: "Recent posts", value: "-posted_at" },
  { label: "Oldest posts", value: "posted_at" },
  { label: "Price Low", value: "amount" },
  { label: "Price High", value: "-amount" },
  { label: "Distance Low", value: "distance" },
  { label: "Distance High", value: "-distance" },
];

export { CarSeaterList, SortByList, SuburbsList, ReportFilterList, ReportSortList, JobFilterList, JobSortByList};

