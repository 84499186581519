import { Autocomplete, Button, Flex, Modal, Table, Text, TextInput } from '@mantine/core'
import { JobInfoProps } from '../CreateJob/type';
import { formattedDateTime } from '../../utils/datetime';
import { useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { API_URL } from '../../services/url';
import axios from 'axios';
import { showNotification } from '@mantine/notifications';

export const JobTable = ({jobdata, onRefetch}:{jobdata:any , onRefetch: () => void}) => {

    type OptionProps = {
        driver_id:string,
        first_name: string | null,
        last_name: string | null,
        email: string,
        phone_number: number,
        license_plate: string
    }

    const [data, setData] = useState<JobInfoProps | null>()
    console.log('Data',data);
    

    const [isAssign, setIsAssign] = useState(false)

    const [assignTo, setAssignTo] = useState('')

    const [optiondata, setOptionData] = useState<OptionProps>()
    
    const [option, setOption] = useState<any>()

    const [opened, { open, close }] = useDisclosure(false);

    const openpopup = (id:string)  => {
        const data = jobdata.find((item:JobInfoProps) => item.id === id);
        setData(data)
        open()
    }

    const fetchOption = async () => {
        if(assignTo.length < 3) return;
        try {
          const response = await axios.get(`${API_URL}search-drivers/?license_plate=${assignTo}`);
          setOptionData(response.data);
          const licensePlates = response.data.results.map((item:any) => item.license_plate);
          setOption(licensePlates);        
        } catch (error) {
            console.log(error);            
        }
    };

    const assignDriver = async () => {
        const driver = optiondata?.driver_id;
        console.log('Driver ID',driver);
        console.log('Job ID',data?.id);       
        try {
            console.log('link',`${API_URL}assign-job/`);            
          const response = await axios.post(`${API_URL}assign-job/`, {
            job_id: data?.id,
            driver_id: driver
          });
          console.log(response.data);          
          showNotification({
            title: 'Driver Assigned',
            message: 'Driver has been assigned to the job',
            color: 'teal',
          });
          closeModel();
          onRefetch()
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchOption()
    }, [assignTo])

    const closeModel = () => {
        setAssignTo('');
        setData(null)
        setIsAssign(false)
        close();
    }

    const rows = jobdata?.map((item:JobInfoProps) => (
        <Table.Tr key={item.id}>
          <Table.Td>{item.name}</Table.Td>
          <Table.Td>{item.booking_id}</Table.Td>
          <Table.Td>{item.status}</Table.Td>
          <Table.Td>${item.amount}</Table.Td>
          <Table.Td>{formattedDateTime(item.posted_at)}</Table.Td>
          <Table.Td>{item.distance}</Table.Td>
          <Table.Td>{item.services_required}</Table.Td>
          <Table.Td><Button color='black' onClick={()=>openpopup(item.id)} >View</Button></Table.Td>
          </Table.Tr>
      ));
  return (
    <>
    <Modal size={'lg'} opened={opened} onClose={closeModel} title="Job Details" centered>
        {data && <Flex direction={'column'} gap={'md'}>
        <Flex justify={'space-between'} px={'xl'}>
            <Flex direction={'column'} gap={'md'}>
                <Flex direction={'column'}>
                    <Text c={'dimmed'}>Name</Text>
                    <Text size='lg'>{data.name}</Text>
                </Flex>
                <Flex direction={'column'}>
                    <Text c={'dimmed'}>Pickup Location</Text>
                    <Text size='lg'>{data.pickupLoc}</Text>
                </Flex>
                <Flex direction={'column'}>
                    <Text c={'dimmed'}>Pickup At</Text>
                    <Text size='lg'>{formattedDateTime(data.pickup_at)}</Text>
                </Flex>
                <Flex direction={'column'}>
                    <Text c={'dimmed'}>Pickup Address</Text>
                    <Text size='lg'>{data.pickup_address}</Text>
                </Flex>
                <Flex direction={'column'}>
                    <Text c={'dimmed'}>Service Required</Text>
                    <Text size='lg'>{data.services_required}</Text>
                </Flex>

            </Flex>

            <Flex direction={'column'} gap={'md'}>
                <Flex direction={'column'}>
                    <Text c={'dimmed'}>Booking ID</Text>
                    <Text size='lg'>{data.booking_id}</Text>
                </Flex>
                <Flex direction={'column'}>
                    <Text c={'dimmed'}>Drop Location</Text>
                    <Text size='lg'>{data.dropLoc}</Text>
                </Flex>
                <Flex direction={'column'}>
                    <Text c={'dimmed'}>Contact</Text>
                    <Text size='lg'>{data.contact}</Text>
                </Flex>
                <Flex direction={'column'}>
                    <Text c={'dimmed'}>Status</Text>
                    <Text size='lg'>{data.status}</Text>
                </Flex>
                <Flex direction={'column'}>
                <Text c={'dimmed'}>Amount</Text>
                <Text size='lg'>{data.amount}</Text>
            </Flex>

            </Flex>

        </Flex>
        <Flex justify={'center'}>
            <Flex direction={'column'} align={'center'}>
                <Text c={'dimmed'}>Assigned to</Text>
                <Text size='lg'>{data.assigned_to? data.assigned_license_plate_number : 'Not Assigned'}</Text>
            </Flex>
        </Flex>
            {isAssign && 
                    <Autocomplete placeholder='Enter License plate number' data={option} limit={5} value={assignTo} onChange={(e) => setAssignTo(e)} />
            }

            {isAssign ? <Flex gap={'xs'}><Button w={'50%'} color='black' onClick={assignDriver}>New Assign</Button> <Button w={'50%'} color='black' variant='outline' onClick={()=>setIsAssign(false)}>Cancel</Button></Flex> 
                : data.status !== 'completed' && data.status !=='cancelled' && data.status !== 'assigned' && <Button color='black' onClick={() => setIsAssign(true)}>Assign</Button> }
        </Flex>
        }
    </Modal>
    <Table highlightOnHover withTableBorder>
        <Table.Thead>
            <Table.Tr>
                <Table.Th ta={'center'}>Name</Table.Th>
                <Table.Th ta={'center'}>Booking ID</Table.Th>
                <Table.Th ta={'center'}>Status</Table.Th>
                <Table.Th ta={'center'}>Amount</Table.Th>
                <Table.Th ta={'center'}>Created At</Table.Th>
                <Table.Th ta={'center'}>Distance</Table.Th>
                <Table.Th ta={'center'}>Service</Table.Th>
                <Table.Th ta={'center'}>view</Table.Th>
            </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
  </Table>
  </>
  )
}
