import { Button, Container, Text, Title } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import React from "react";

const SuccessfulRegistration: React.FC = () => {
  return (
    <Container>
      <IconCheck size={64} />
      <Title order={2}>Registration Successful!</Title>
      <Text size="lg">
        Congratulations! Your account has been successfully registered.
      </Text>
      <Button variant="filled" color="green" component="a" href="/login">
        Login Now
      </Button>
    </Container>
  );
};

export default SuccessfulRegistration;
