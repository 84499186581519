import {
  Button,
  Center,
  Container,
  Flex,
  Stack,
  Text,
  TextInput,
  rem,
} from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import axios from "axios";
import { useState } from "react";
import { API_URL } from "../../services/url";

type Props = {post_by:string};

export const InviteDriver: React.FC<Props> = ({post_by}) => {
  const urlParams = new URLSearchParams(window.location.search);

  const jobId = urlParams.get("id");

  const [lp_number, setLp_number] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean | null>(null);  

  const userId = localStorage.getItem("userId"); 

  // function validateEmail() {
  //   // Regular expression for email validation
  //   const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //   if (!regex.test(email)) {
  //     setError("Invalid email address");
  //   } else {
  //     setError("");
  //   }
  // }

  const handleInvite = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${API_URL}create-request/create_or_get_invite/?license_plate_number=${lp_number}&job=${jobId}`
      );
      if (res.data && res.data.id) setSuccess(true);
      else setSuccess(false);
    } catch (error) {
      console.log(error);
      setError("Check the license plate number");
    } finally {
      setLoading(false);
    }
  };

  const handleSendAnotherInvitation = () => {
    setLp_number("");
    setLoading(false);
    setError("");
    setSuccess(null);
  };

  return (
    <Container>
      <Flex gap={"lg"} direction={"column"}>
        {success !== null ? (
          <Stack gap={"xs"}>
            <Center maw={700} h={"100%"}>
              <Flex
                justify={"center"}
                align={"center"}
                w={100}
                h={100}
                bg={success ? "green" : "red"}
                style={{ borderRadius: "50px" }}
              >
                {success ? (
                  <IconCheck
                    color="white"
                    style={{ width: rem(60), height: rem(60) }}
                  />
                ) : (
                  <IconX
                    color="white"
                    style={{ width: rem(60), height: rem(60) }}
                  />
                )}
              </Flex>
            </Center>
            <Flex justify={"center"}>
              <Button
                w={"90%"}
                size="md"
                variant="gradient"
                gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
                radius={"md"}
                c={"fcolor"}
                mt={"lg"}
                onClick={handleSendAnotherInvitation}
                loaderProps={{ type: "oval" }}
              >
                {success ? "Send Another Invitation" : "Try Again"}
              </Button>
            </Flex>
          </Stack>
        ) : (
          <Stack gap={"xs"}>
            <TextInput
              label="License Plate Number"
              placeholder="Enter your license plate number"
              type="text"
              data-autofocus
              size="md"
              required
              labelProps={{ style: { alignSelf: "flex-start" } }}
              value={lp_number}
              onChange={(e) => setLp_number(e.target.value)}
              onFocusCapture={(e) => setError("")}
              // onBlurCapture={validateEmail}
            />
            {error && (
              <Text color="red" size="sm" my="md">
                {error}
              </Text>
            )}
            <Flex justify={"center"}>
              <Button
                w={"90%"}
                size="md"
                variant="gradient"
                gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
                radius={"md"}
                c={"fcolor"}
                mt={"lg"}
                disabled={loading || error !== ""}
                onClick={handleInvite}
                loading={loading}
                loaderProps={{ type: "oval" }}
              >
                Send Invitation
              </Button>
            </Flex>
          </Stack>
        )}
      </Flex>
    </Container>
  );
};
