import {
  Button,
  Container,
  Stack,
  Text,
  Image,
  Title,
  Flex,
  Space,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useLayoutEffect } from "react";
import checkUserId from "../../utils/checkuserid";

const LandingPage = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  useLayoutEffect(() => {
    checkUserId();
  }, []);

  return (
    <Container
      size={isMobile ? "xs" : "sm"}
      px="xs"
      py={isMobile ? "md" : "xl"}
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderWidth: 1,
        borderColor: "red",
      }}
      bg={"bg"}
    >
      <Stack align="center" justify="center" style={{ width: "90%" }}>
        <Flex direction={"column"} align={"center"} gap={"xs"}>
          <Image src={"landingimg.png"} />
          <Title order={1} style={{ fontSize: isMobile ? "2rem" : "4rem" }}>
            Kabbie
          </Title>
          <Text size="sm">Ride as one community.</Text>
        </Flex>
        <Space h={5} />
        <Flex direction={"column"} w={"100%"} align={"center"} gap={"sm"}>
          <Button
            onClick={() => {
              window.location.href = "/signin";
            }}
            variant="gradient"
            w={"90%"}
            size="md"
            radius={"md"}
            gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
            c={"fcolor"}
          >
            Login
          </Button>
          <Button
            variant="outline"
            w={"90%"}
            size="md"
            radius={"md"}
            color="primary"
            onClick={() => {
              window.location.href = "/signup";
            }}
          >
            Sign Up
          </Button>
        </Flex>
      </Stack>
    </Container>
  );
};

export default LandingPage;
