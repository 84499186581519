const DriverPathList = [
  "/home",
  "/createjob",
  "/jobschedule",
  "/personal",
  "/address",
  "/license",
  "/vehicle",
  "/job",
  "/profile",
  "/notifications",
];

const AuthPathList = ["/", "/signin", "/signup", ""];

const checkUserId = () => {
  const driverId = localStorage.getItem("userId");

  if (driverId === null || driverId === undefined) {
    if (AuthPathList.includes(window.location.pathname)) return;
    window.location.href = "/";
    return;
  } else {
    if (DriverPathList.includes(window.location.pathname)) return;
    window.location.href = "/home";
  }
};

export default checkUserId;
