import {
  Button,
  Container,
  Flex,
  LoadingOverlay,
  Select,
  Stack,
  TextInput,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { useState } from "react";
import { API_URL } from "../../services/url";

type Props = {
  // Prop types for children components
  onNext?: () => void;
};

const AddressDetails = (Props: Props) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [loading, setLoading] = useState(false);
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [zipPostalCode, setZipPostalCode] = useState("");
  const [country, setCountry] = useState<string | null>("");

  const isFormValid =
    streetAddress && city && stateProvince && zipPostalCode && country;

  const handleSubmit = async () => {
    if (!isFormValid) {
      showNotification({
        title: "Validation Error",
        message: "Please fill in all the required fields.",
        color: "red",
      });
      return;
    }

    setLoading(true);

    try {
      const userId = localStorage.getItem("userId");
      if (userId === null || userId === undefined) {
        window.location.href = "/signin";
        return;
      }
      const response = await axios.post(`${API_URL}driver-address/`, {
        street_address: streetAddress,
        city: city,
        state: stateProvince,
        zip_code: zipPostalCode,
        country: country,
        driver: userId,
      });

      // Handle successful response
      console.log(response.data);
      showNotification({
        title: "Success",
        message: "Address details updated successfully.",
        color: "green",
      });

      if (Props?.onNext) Props.onNext();

      // window.location.href = '/license';
    } catch (error) {
      // Handle error
      console.error(error);
      showNotification({
        title: "Error",
        message: "An error occurred while updating address details.",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      size={isMobile ? "xs" : "sm"}
      px="lg"
      py={isMobile ? "md" : "xl"}
      style={{
        // height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "self-start",
        textAlign: "left",
      }}
      bg={"bg"}
    >
      <LoadingOverlay visible={loading} />
      <h2>Address</h2>
      <Stack style={{ width: "100%" }}>
        <TextInput
          label="Street Address"
          placeholder="Enter your street address"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={streetAddress}
          onChange={(event) => setStreetAddress(event.currentTarget.value)}
        />

        <TextInput
          label="City"
          placeholder="Eg: Sydney"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={city}
          onChange={(event) => setCity(event.currentTarget.value)}
        />

        <TextInput
          label="State/Province"
          placeholder="Eg: NSW"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={stateProvince}
          onChange={(event) => setStateProvince(event.currentTarget.value)}
        />

        <TextInput
          label="Zip/Postal Code"
          placeholder="Eg: 2001"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={zipPostalCode}
          onChange={(event) => setZipPostalCode(event.currentTarget.value)}
        />

        <Select
          label="Country"
          placeholder="Select your country"
          required
          mb="sm"
          size="md"
          defaultValue={"australia"}
          labelProps={{ style: { alignSelf: "flex-start" } }}
          data={[{ value: "australia", label: "Australia" }]}
          value={country}
          onChange={(value) => setCountry(value)}
        />

        <Flex justify={"center"}>
          <Button
            w={"90%"}
            size="md"
            variant="gradient"
            gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
            onClick={handleSubmit}
            disabled={!isFormValid || loading}
            loading={loading}
            loaderProps={{ type: "oval" }}
            radius={"md"}
            mt={"sm"}
          >
            Next
          </Button>
        </Flex>
      </Stack>
    </Container>
  );
};

export default AddressDetails;
